define("ember-models-table/components/models-table/row", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/array", "ember-models-table/templates/components/models-table/row"], function (_exports, _component, _component2, _object, _computed, _array, _row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Table body row is used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each Body.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} />
   *       {{/each}}
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each Body.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} as |Row|>
   *           {{#each MT.visibleProcessedColumns as |column|}}
   *             <Row.Cell @column={{column}} @index={{index}} />
   *           {{/each}}
   *         </Body.Row>
   *       {{/each}}
   *       {{! ... }}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * **Important!** You must bound `record` and `index` to the `row`.
   *
   * ModelsTableTableRow yields references to the following contextual components:
   *
   * * [models-table/cell](Components.ModelsTableCell.html) - component represents each row's cell
   * * [models-table/row-group-toggle](Components.ModelsTableRowGroupToggle.html) - component is used to toggle rows group visibility
   * * [models-table/row-select-checkbox](Components.ModelsTableRowSelectCheckbox.html) - component is used to toggle row selection
   * * [models-table/expand-toggle](Components.ModelsTableExpandToggle.html) - component is used to expand or collapse row
   *
   * Check own docs for each component to get detailed info.
   *
   * References to the following properties are yielded:
   *
   * * [isEditRow](Components.ModelsTableRow.html#property_isEditRow) - `true` if row in the Edit-mode
   * * [isFirstGroupedRow](Components.ModelsTableRow.html#property_isFirstGroupedRow) - `true` if row is first in the rows group (flag used when rows grouping is used)
   * * [rowspanForFirstCell](Component.ModelsTablRow.html#property_rowspanForFirstCell)
   *
   * References to the following actions are yielded:
   *
   * * [editRow](Components.ModelsTableRow.html#event_editRow) - action to set row to the Edit-mode
   * * [saveRow](Components.ModelsTableRow.html#event_saveRow) - action to save row and turn off Edit-mode
   * * [cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow) - action to cancel changes done to the row and turn off Edit-mode
   *
   * @class ModelsTableRow
   * @namespace Components
   * @extends Ember.Component
   */
  let RowComponent = _exports.default = (_dec = (0, _component.layout)(_row.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _object.computed)('isSelected', 'themeInstance.selectedRow'), _dec4 = (0, _object.computed)('isExpanded', 'themeInstance.expandedRow'), _dec5 = (0, _object.computed)('visibleGroupedItems.length', 'expandedGroupItems.length', 'groupSummaryRowComponent'), _dec6 = (0, _computed.intersect)('selectedItems', 'groupedItems'), _dec7 = (0, _computed.intersect)('expandedItems', 'groupedItems'), _dec8 = (0, _computed.intersect)('expandedItems', 'visibleGroupedItems'), _dec9 = (0, _computed.equal)('index', 0), _dec10 = (0, _object.computed)('selectedItems.[]', 'record'), _dec11 = (0, _object.computed)('expandedItems.[]', 'record'), _dec12 = (0, _object.computed)('displayGroupedValueAs', 'isFirstGroupedRow', 'useDataGrouping'), _dec(_class = _dec2(_class = (_class2 = class RowComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * Row's index
       *
       * @property index
       * @type number
       * @default null
       */
      _defineProperty(this, "index", null);
      /**
       * One of the [data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @type object
       * @default null
       */
      _defineProperty(this, "record", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property currentGroupingPropertyName
       * @type string
       * @default null
       */
      _defineProperty(this, "currentGroupingPropertyName", null);
      /**
       * Bound from [ModelsTable.collapsedGroupValues](Components.ModelsTable.html#property_collapsedGroupValues)
       *
       * @property collapsedGroupValues
       * @type array
       * @default null
       */
      _defineProperty(this, "collapsedGroupValues", null);
      /**
       * @property groupedItems
       * @type object[]
       * @default null
       * @private
       */
      _defineProperty(this, "groupedItems", null);
      /**
       * @property visibleGroupedItems
       * @type object[]
       * @default null
       * @private
       */
      _defineProperty(this, "visibleGroupedItems", void 0);
      /**
       * Bound from [ModelsTable.useDataGrouping](Components.ModelsTable.html#property_useDataGrouping)
       *
       * @property useDataGrouping
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useDataGrouping", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @type string
       * @default null
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * @protected
       * @property selectedGroupedItems
       * @type object[]
       * @default []
       */
      _initializerDefineProperty(this, "selectedGroupedItems", _descriptor, this);
      /**
       * @protected
       * @property expandedGroupedItems
       * @type object[]
       * @default []
       */
      _initializerDefineProperty(this, "expandedGroupedItems", _descriptor2, this);
      /**
       * @property expandedGroupItems
       * @protected
       * @type object[]
       * @default []
       */
      _initializerDefineProperty(this, "expandedGroupItems", _descriptor3, this);
      /**
       * @property isFirstGroupedRow
       * @protected
       * @type number
       * @default false
       */
      _initializerDefineProperty(this, "isFirstGroupedRow", _descriptor4, this);
      /**
       * @property groupedValue
       * @type *
       * @default null
       */
      _defineProperty(this, "groupedValue", null);
      /**
       * Rows group size where current row is
       *
       * @property groupedLength
       * @type number
       * @default null
       */
      _defineProperty(this, "groupedLength", null);
      /**
       * Closure action [ModelsTable.clickOnRow](Components.ModelsTable.html#event_clickOnRow)
       *
       * @event clickOnRow
       */
      _defineProperty(this, "clickOnRow", null);
      /**
       * Closure action [ModelsTable.doubleClickOnRow](Components.ModelsTable.html#event_doubleClickOnRow)
       *
       * @event doubleClickOnRow
       */
      _defineProperty(this, "doubleClickOnRow", null);
      /**
       * Closure action [ModelsTable.hoverOnRow](Components.ModelsTable.html#event_hoverOnRow)
       *
       * @event hoverOnRow
       */
      _defineProperty(this, "hoverOnRow", null);
      /**
       * Closure action [ModelsTable.outRow](Components.ModelsTable.html#event_outRow)
       *
       * @event outRow
       */
      _defineProperty(this, "outRow", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRows](Components.ModelsTable.html#event_toggleGroupedRows)
       *
       * @event toggleGroupedRows
       */
      _defineProperty(this, "toggleGroupedRows", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsSelection](Components.ModelsTable.html#event_toggleGroupedRowsSelection)
       *
       * @event toggleGroupedRowsSelection
       */
      _defineProperty(this, "toggleGroupedRowsSelection", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsExpands](Components.ModelsTable.html#event_toggleGroupedRowsExpands)
       *
       * @event toggleGroupedRowsExpands
       */
      _defineProperty(this, "toggleGroupedRowsExpands", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Is the row in edit mode
       *
       * @property isEditRow
       * @type boolean
       * @default false
       */
      _defineProperty(this, "isEditRow", false);
    }
    /**
     * @property tagName
     * @type string
     * @default 'tr'
     */

    /**
     * @property rowSelectedClass
     * @protected
     * @type string
     * @default ''
     */
    get rowSelectedClass() {
      return this.isSelected ? this.themeInstance.selectedRow : '';
    }

    /**
     * @property rowExpandedClass
     * @protected
     * @type string
     * @default ''
     */
    get rowExpandedClass() {
      return this.isExpanded ? this.themeInstance.expandedRow : '';
    }

    /**
     * @property rowspanForFirstCell
     * @type number
     * @protected
     */
    get rowspanForFirstCell() {
      const visibleGroupedItemsLength = this.visibleGroupedItems ? this.visibleGroupedItems.length : 0;
      const expandedGroupItemsLength = this.expandedGroupItems ? this.expandedGroupItems.length : 0;
      const rowspan = visibleGroupedItemsLength + expandedGroupItemsLength;
      return this.groupSummaryRowComponent ? rowspan + 1 : rowspan;
    }
    /**
     * @protected
     * @property isSelected
     * @type boolean
     * @default false
     */
    get isSelected() {
      return (0, _array.isArray)(this.selectedItems) && this.selectedItems.includes(this.record);
    }

    /**
     * @protected
     * @property isExpanded
     * @type boolean
     * @default false
     */
    get isExpanded() {
      return (0, _array.isArray)(this.expandedItems) && this.expandedItems.includes(this.record);
    }

    /**
     * @protected
     * @property shouldShowGroupToggleCell
     * @type boolean
     * @default false
     */
    get shouldShowGroupToggleCell() {
      return this.displayGroupedValueAs === 'column' && this.isFirstGroupedRow && this.useDataGrouping;
    }
    click() {
      this.clickOnRow(this.index, this.record);
      return false;
    }
    doubleClick() {
      this.doubleClickOnRow(this.index, this.record);
    }
    enter() {
      this.hoverOnRow(this.index, this.record);
    }
    leave() {
      this.outRow(this.index, this.record);
    }
    didInsertElement() {
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);
      super.didInsertElement(...arguments);
    }
    willDestroyElement() {
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
      super.willDestroyElement(...arguments);
    }

    /**
     * @protected
     * @event handleMouseEnter
     */
    handleMouseEnter() {
      this.enter();
    }

    /**
     * @protected
     * @event handleMouseLeave
     */
    handleMouseLeave() {
      this.leave();
    }

    /**
     * @protected
     * @event doToggleGroupedRows
     */
    doToggleGroupedRows() {
      this.toggleGroupedRows(this.groupedValue);
      return false;
    }

    /**
     * Place a row into edit mode
     *
     * @protected
     * @event editRow
     */
    editRow() {
      (0, _object.set)(this, 'isEditRow', true);
    }

    /**
     * Indicate a row has been saved, the row is no longer in edit mode
     *
     * @protected
     * @event saveRow
     */
    saveRow() {
      (0, _object.set)(this, 'isEditRow', false);
    }

    /**
     * Indicate the edit on the row has been cancelled, the row is no longer in edit mode
     *
     * @protected
     * @event cancelEditRow
     */
    cancelEditRow() {
      (0, _object.set)(this, 'isEditRow', false);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "rowSelectedClass", [_component.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "rowSelectedClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rowExpandedClass", [_component.className, _dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "rowExpandedClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rowspanForFirstCell", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "rowspanForFirstCell"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "selectedGroupedItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "expandedGroupedItems", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "expandedGroupItems", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isFirstGroupedRow", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isSelected", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isExpanded", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "isExpanded"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldShowGroupToggleCell", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldShowGroupToggleCell"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseEnter", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseEnter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseLeave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseLeave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doToggleGroupedRows", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRows"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "editRow"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "saveRow"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelEditRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelEditRow"), _class2.prototype)), _class2)) || _class) || _class);
});