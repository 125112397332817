define("ember-time-field/states/hours-focused", ["exports", "ember-time-field/utils/state", "ember-time-field/utils/codes"], function (exports, _state, _codes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _state.default.extend({
    initialState: "digit1",

    digit1: (0, _state.state)(_state.default, {
      key(manager, code) {
        if (!(0, _codes.isNumberCode)(code)) {
          return; // no-op
        }

        const num = (0, _codes.keyCodeToNumber)(code);
        manager.get("input").setHours(num);

        if (num <= 2) {
          manager.transitionTo("digit2");
        } else {
          manager.transitionTo("minutes");
        }
      }
    }),

    digit2: (0, _state.state)(_state.default, {
      key(manager, code) {
        if (!(0, _codes.isNumberCode)(code)) {
          return; // no-op
        }

        const num = (0, _codes.keyCodeToNumber)(code);
        manager.get("input").setHoursDigit2(num);
        manager.transitionTo("minutes");
      }
    }),

    enter(manager) {
      this.focusIn(manager);
    },

    focusIn(manager) {
      manager.get("input").selectHours();
    },

    right(manager) {
      manager.transitionTo("minutes");
    },

    up(manager) {
      manager.get("input").incrementHours();
    },

    down(manager) {
      manager.get("input").decrementHours();
    },

    key(manager, code) {
      if (!(0, _codes.isNumberCode)(code)) {
        return; // no-op
      }

      const num = (0, _codes.keyCodeToNumber)(code);
      manager.get("input").setHours(num);

      if (num <= 2) {
        manager.transitionTo("digit2");
      } else {
        manager.transitionTo("minutes");
      }
    }

  });
});