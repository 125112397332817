define("ember-google-maps/utils/helpers", ["exports", "@ember/object", "@ember/object/proxy", "@ember/object/promise-proxy-mixin", "rsvp"], function (_exports, _object, _proxy, _promiseProxyMixin, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedPromise = computedPromise;
  _exports.position = position;
  _exports.promisify = promisify;
  function position() {
    const {
      lat,
      lng
    } = (0, _object.getProperties)(this, 'lat', 'lng');
    return lat && lng ? new google.maps.LatLng(lat, lng) : undefined;
  }
  let ObjectPromiseProxy = _proxy.default.extend(_promiseProxyMixin.default);
  function computedPromise(...args) {
    let func = args.pop();
    return (0, _object.computed)(...args, function () {
      return ObjectPromiseProxy.create({
        promise: func.apply(this)
      });
    });
  }
  function promisify(maybePromise) {
    return maybePromise instanceof _rsvp.Promise ? maybePromise : (0, _rsvp.resolve)(maybePromise);
  }
});