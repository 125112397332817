define("ember-models-table/components/models-table/group-summary-row", ["exports", "@ember-decorators/component", "@ember/component", "ember-models-table/templates/components/models-table/group-summary-row", "@ember/object/computed"], function (_exports, _component, _component2, _groupSummaryRow, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * @class ModelsTableGroupSummaryRow
   * @namespace Components
   * @extends Ember.Component
   */
  let GroupSummaryRowComponent = _exports.default = (_dec = (0, _component.layout)(_groupSummaryRow.default), _dec2 = (0, _component.classNames)('group-summary-row'), _dec3 = (0, _component.tagName)('tr'), _dec4 = (0, _computed.intersect)('selectedItems', 'groupedItems'), _dec5 = (0, _computed.intersect)('expandedItems', 'groupedItems'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class GroupSummaryRowComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'tr'
       */
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.groupedItems](Components.ModelsTable.html#property_groupedItems)
       *
       * @property groupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "groupedItems", null);
      /**
       * @property visibleGroupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "visibleGroupedItems", null);
      /**
       * @property selectedGroupedItems
       * @type object[]
       * @default null
       * @protected
       */
      _initializerDefineProperty(this, "selectedGroupedItems", _descriptor, this);
      /**
       * @property expandedGroupedItems
       * @type object[]
       * @default null
       * @protected
       */
      _initializerDefineProperty(this, "expandedGroupedItems", _descriptor2, this);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "selectedGroupedItems", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "expandedGroupedItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class) || _class);
});