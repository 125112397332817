define('ember-time-field/utils/state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.state = state;
  function state(klass, attrs = {}) {
    return Ember.computed({
      get() {
        let manager = this.get('manager') || this;
        let parent = this;
        return klass.create(Object.assign(attrs, { manager, parent }));
      }
    });
  }

  exports.default = Ember.Object.extend({

    send(name, ...args) {
      if (this[name]) {
        this[name].apply(this, [this.get('manager'), ...args]);
      } else if (this.get('parent')) {
        this.get('parent').send(name, ...args);
      } else {
        throw new Error(`Unhandled event ${name}`);
      }
    }

  });
});