define("ember-models-table/helpers/is-equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqual = isEqual;
  function isEqual([left, right, type] /*, hash*/) {
    if (type === 'number') {
      return Number(left) === Number(right);
    }
    return left === right;
  }
  var _default = _exports.default = (0, _helper.helper)(isEqual);
});