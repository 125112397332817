define('ember-maskmoney/components/imput-money', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    prefix: '',
    suffix: '',
    affixesStay: false,
    thousands: ',',
    decimal: '.',
    precision: 2,
    allowZero: true,
    allowNegative: false,
    allowDecimal: true,

    options: Ember.computed('prefix', 'suffix', 'affixesStay', 'thousands', 'decimal', 'precision', 'allowZero', 'allowNegative', 'allowDecimal', function () {
      return {
        prefix: this.get('prefix'),
        suffix: this.get('suffix'),
        affixesStay: this.get('affixesStay'),
        thousands: this.get('thousands'),
        decimal: this.get('decimal'),
        precision: this.get('precision'),
        allowZero: this.get('allowZero'),
        allowNegative: this.get('allowNegative'),
        allowDecimal: this.get('allowDecimal')
      };
    }),

    didInsertElement() {
      Ember.run.once(() => {
        this.$().maskMoney(this.get('options'));
        if (this.get('allowZero') && this.get('number') !== undefined || this.get('number')) {
          this.notifyPropertyChange('number');
        }
      });
      this._super(...arguments);
    },

    willDestroyElement() {
      this.$().maskMoney('destroy');
      this._super(...arguments);
    },

    setMask: Ember.observer('options', function () {
      this.$().maskMoney(this.get('options'));
      this.$().maskMoney('mask');
    }),

    setMaskedValue: Ember.observer('number', 'precision', 'decimal', function () {
      let number = parseFloat(this.get('number') || 0).toFixed(this.get('precision'));
      let val = number.toString().replace('.', this.get('decimal'));
      this.$().val(val);
      this.$().maskMoney('mask');
    }),

    setUnmaskedValue: Ember.observer('value', 'allowDecimal', function () {
      if (this.get('allowDecimal')) {
        this.set('number', this.$().maskMoney('unmasked')[0]);
      } else {
        this.set('number', this.get('value').replace(/[^0-9]/g, ''));
      }
    })
  });
});