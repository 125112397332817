define("ember-wistia/services/wistia", ["exports", "ember", "@ember/service", "rsvp", "@ember/object", "@ember/runloop"], function (_exports, _ember, _service, _rsvp, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Logger
  } = _ember.default;
  var _default = _exports.default = _service.default.extend({
    currentlyPlaying: null,
    addVideo(matcher, userEmail = null) {
      window._wq = window._wq || [];
      window._wq.push({
        [matcher]: video => {
          video.bind('pause', () => {
            this.clearCurrentlyPlaying(video);
          });
          video.bind('play', () => {
            this.setCurrentlyPlaying(video);
            this._setAutoPausing(video);
            this._maybeRecordEmail(video, userEmail);
          });
        }
      });
    },
    bindVideoEvent(matcher, ...rest) {
      let Wistia = this._wistiaApi();
      (0, _runloop.later)(this, () => {
        let video = Wistia.api(matcher);
        video.bind(...rest);
      }, 500);
    },
    clearCurrentlyPlaying(video) {
      let hashedId = video.hashedId();
      if ((0, _object.get)(this, 'currentlyPlaying') === hashedId) {
        (0, _object.set)(this, 'currentlyPlaying', null);
      }
    },
    getCurrentlyPlaying() {
      return (0, _object.get)(this, 'currentlyPlaying');
    },
    getVideo(matcher) {
      let Wistia = this._wistiaApi();
      return new _rsvp.Promise(function (resolve, reject) {
        (0, _runloop.later)(this, () => {
          let video = Wistia.api(matcher);
          if (video) {
            resolve(video);
          } else {
            reject({
              msg: 'No video was found'
            });
          }
        }, 500);
      });
    },
    _maybeRecordEmail(video, userEmail) {
      if (userEmail) {
        video.email(userEmail);
      }
    },
    _setAutoPausing(current) {
      let allVideos = window.Wistia.api.all();
      allVideos.forEach(video => {
        if (video.hashedId() !== current.hashedId()) {
          video.pause();
        }
      });
    },
    setCurrentlyPlaying(video) {
      let hashedId = video.hashedId();
      (0, _object.set)(this, 'currentlyPlaying', hashedId);
    },
    _wistiaApi() {
      if (window.Wistia) {
        return window.Wistia;
      } else {
        return {
          api(matcher) {
            return Logger.log(`This API is disabled in testing for: ${matcher}`);
          }
        };
      }
    }
  });
});