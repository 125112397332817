define("ember-models-table/components/models-table/themes/ember-paper/columns-dropdown", ["exports", "@ember-decorators/component", "@ember/object/computed", "ember-models-table/components/models-table/columns-dropdown", "ember-models-table/templates/components/models-table/themes/ember-paper/columns-dropdown"], function (_exports, _component, _computed, _columnsDropdown, _columnsDropdown2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * @class EpModelsTableColumnsDropdown
   * @namespace Components
   * @extends Components.ModelsTableColumnsDropdown
   */
  let EpModelsTableColumnsDropdownComponent = _exports.default = (_dec = (0, _component.layout)(_columnsDropdown2.default), _dec2 = (0, _computed.alias)('themeInstance.columnsDropdownWrapper'), _dec(_class = (_class2 = class EpModelsTableColumnsDropdownComponent extends _columnsDropdown.default {
    constructor(...args) {
      super(...args);
      /**
       * @property columnsDropdownWrapper
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "columnsDropdownWrapper", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "columnsDropdownWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
});