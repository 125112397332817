define("ember-google-maps/helpers/g-map/compute", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.gMapCompute = gMapCompute;
  // Same as ember-composable-helpers 'compute'.
  function gMapCompute([action, ...params]) {
    return action(...params);
  }
  var _default = _exports.default = (0, _helper.helper)(gMapCompute);
});