define("ember-google-maps/components/g-map/control", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/control", "ember-google-maps/utils/options-and-events", "@ember/object", "rsvp"], function (_exports, _mapComponent, _control, _optionsAndEvents, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Control
   * @namespace GMap
   * @module ember-google-maps/components/g-map/control
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _control.default,
    _type: 'control',
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'index', 'class']),
    _addComponent() {
      let _elementDestination = (0, _object.set)(this, '_elementDestination', document.createElement('div'));
      let {
        map,
        class: classNames,
        index
      } = (0, _object.getProperties)(this, ['map', 'class', 'index']);
      if (classNames) {
        _elementDestination.classList.add(classNames);
      }
      if (Number.isInteger(index)) {
        _elementDestination.index = index;
      }
      let controlPosition = google.maps.ControlPosition[(0, _object.get)(this, 'position')];
      map.controls[controlPosition].push(_elementDestination);
      return (0, _rsvp.resolve)((0, _object.set)(this, 'mapComponent', _elementDestination));
    },
    _updateComponent() {}
  });
});