define("ember-useragent/services/user-agent", ["exports", "@ember/service", "@ember/object", "@ember/application", "@ember/application/deprecations", "ua-parser-js"], function (_exports, _service, _object, _application, _deprecations, _uaParserJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    _parser: (0, _object.computed)({
      get() {
        if (this.__parser) {
          return this.__parser;
        } else {
          return new _uaParserJs.default();
        }
      },
      set(key, value) {
        this.__parser = value;
      }
    }),
    parser: (0, _object.computed)('extensions', {
      get() {
        (true && !(false) && (0, _deprecations.deprecate)('Usage of the private property `parser` is deprecated. If you need to fiddle around with this private property, please open an issue and we can discuss.', false, {
          id: 'ember-useragent.service.parser',
          until: '1.0.0'
        }));
        return (0, _object.get)(this, '_parser');
      }
    }).readOnly(),
    UAParser: (0, _object.computed)(function () {
      (true && !(false) && (0, _deprecations.deprecate)('Usage of the property `UAParser` is deprecated. To get the UAParser class, import it as `import UAParser from \'ua-parser-js\';`. To get the instance of that class used by this service, as this property previously incorrectly returned, get the `parser` property.', false, {
        id: 'ember-useragent.service.UAParser',
        until: '1.0.0'
      }));
      return (0, _object.get)(this, '_parser');
    }),
    userAgent: (0, _object.computed)({
      get() {
        const parser = (0, _object.get)(this, '_parser');
        return parser.getUA();
      },
      set(key, value) {
        const parser = (0, _object.get)(this, '_parser');
        parser.setUA(value);
        (0, _object.set)(this, '__parser', parser);
        return value;
      }
    }),
    fastboot: (0, _object.computed)(function () {
      (true && !(false) && (0, _deprecations.deprecate)('Usage of the private property `fastboot` is deprecated. Inject the `fastboot` service yourself instead.', false, {
        id: 'ember-useragent.service.fastboot',
        until: '1.0.0'
      }));
      return (0, _application.getOwner)(this).lookup('service:fastboot');
    }),
    isFastBoot: (0, _object.computed)(function () {
      (true && !(false) && (0, _deprecations.deprecate)('Usage of the private property `isFastBoot` is deprecated. Inject the `fastboot` service yourself and get `fastboot.isFastBoot` instead.', false, {
        id: 'ember-useragent.service.isFastBoot',
        until: '1.0.0'
      }));
      const fastboot = (0, _application.getOwner)(this).lookup('service:fastboot');
      return Boolean(fastboot && (0, _object.get)(fastboot, 'isFastBoot'));
    }),
    browser: (0, _object.computed)('_parser', function () {
      const browser = (0, _object.get)(this, '_parser').getBrowser();
      return {
        info: browser,
        isChrome: browser.name === 'Chrome',
        isChromeHeadless: browser.name === 'Chrome Headless',
        isEdge: browser.name === 'Edge',
        isFirefox: browser.name === 'Firefox',
        isIE: browser.name === 'IE' || browser.name === 'IE Mobile',
        isSafari: browser.name === 'Safari' || browser.name === 'Mobile Safari'
      };
    }),
    cpu: (0, _object.computed)('_parser', function () {
      return (0, _object.get)(this, '_parser').getCPU();
    }),
    device: (0, _object.computed)('_parser', function () {
      const device = (0, _object.get)(this, '_parser').getDevice();
      return {
        info: device,
        isConsole: device.type === 'console',
        isDesktop: !device.type,
        isMobile: device.type === 'mobile',
        isTablet: device.type === 'tablet'
      };
    }),
    engine: (0, _object.computed)('_parser', function () {
      const engine = (0, _object.get)(this, '_parser').getEngine();
      return {
        info: engine,
        isWebkit: engine.name === 'WebKit'
      };
    }),
    os: (0, _object.computed)('_parser', function () {
      const os = (0, _object.get)(this, '_parser').getOS();
      return {
        info: os,
        isAndroid: os.name === 'Android',
        isIOS: os.name === 'iOS',
        isLinux: ['CentOS', 'Fedora', 'Linpus', 'Linux', 'MeeGo', 'PCLinuxOS', 'RedHat', 'SUSE', 'Ubuntu', 'VectorLinux'].indexOf(os.name) > -1,
        isMacOS: os.name === 'Mac OS',
        isWindows: ['Windows', 'Windows Phone', 'Windows Mobile'].indexOf(os.name) > -1
      };
    }),
    setupService() {
      (true && !(false) && (0, _deprecations.deprecate)('Usage of the private method `setupService` is deprecated. To force an update, set the `userAgent` property.', false, {
        id: 'ember-useragent.service.setupService',
        until: '1.0.0'
      }));
      this.notifyPropertyChange('userAgent');
    }
  });
});