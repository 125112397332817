define("ember-models-table/components/models-table/page-size-select", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-models-table/templates/components/models-table/page-size-select"], function (_exports, _component, _component2, _object, _computed, _pageSizeSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Dropdown with page size values used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Value from [ModelsTable.pageSizeOptions](Components.ModelsTable.html#property_pageSizeOptions) is used as a list of dropdown-options.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect as |SizeSelectBlock|>
   *       <SizeSelectBlock.Select />
   *     </Footer.PageSizeSelect>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * ModelsTablePageSizeSelect yields references to the following contextual components:
   *
   * * Select - selectbox with list of available page size options
   *
   * @class ModelsTablePageSizeSelect
   * @namespace Components
   * @extends Ember.Component
   */
  let PageSizeSelectComponent = _exports.default = (_dec = (0, _component.layout)(_pageSizeSelect.default), _dec2 = (0, _computed.alias)('themeInstance.pageSizeWrapper'), _dec3 = (0, _object.computed)('elementId'), _dec(_class = (_class2 = class PageSizeSelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property pageSizeWrapper
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "pageSizeWrapper", _descriptor, this);
      /**
       * Bound from [ModelsTable.pageSizeOptions](Components.ModelsTable.html#property_pageSizeOptions)
       *
       * @property pageSizeOptions
       * @type SelectOption[]
       * @default null
       */
      _defineProperty(this, "pageSizeOptions", null);
      /**
       * Bound from [ModelsTable.pageSize](Components.ModelsTable.html#property_pageSize)
       *
       * @property pageSize
       * @type number
       * @default 10
       */
      _defineProperty(this, "pageSize", 10);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-page-size-select`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "pageSizeWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype)), _class2)) || _class);
});