define("ember-google-maps/components/g-map/directions", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/directions", "ember-google-maps/utils/options-and-events", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/array", "rsvp", "@ember/runloop", "ember-concurrency"], function (_exports, _mapComponent, _directions, _optionsAndEvents, _service, _object, _computed, _array, _rsvp, _runloop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DirectionsAPI = DirectionsAPI;
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function DirectionsAPI(source) {
    let mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      get directions() {
        return source.directions;
      },
      get waypoints() {
        return source.waypoints;
      },
      actions: {
        route: () => source.route()
      }
    });
  }

  /**
   * A wrapper for the google.maps.directionsService API.
   *
   * @class Directions
   * @namespace GMap
   * @module ember-google-maps/components/g-map/directions
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    googleMapsApi: (0, _service.inject)(),
    layout: _directions.default,
    _type: 'directions',
    _pluralType: 'directions',
    directionsService: (0, _computed.reads)('googleMapsApi.directionsService'),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'onDirectionsChanged']),
    _createOptions(options) {
      return _objectSpread(_objectSpread({}, options), (0, _object.getProperties)(this, ['origin', 'destination', 'travelMode', 'waypoints']));
    },
    init() {
      this._super(...arguments);
      this.waypoints = (0, _array.A)();
      this.publicAPI = DirectionsAPI(this);
    },
    _addComponent(options) {
      return this.route(options);
    },
    _updateComponent(_, options) {
      return this.route(options);
    },
    _didAddComponent() {
      let watched = (0, _optionsAndEvents.watch)(this, {
        'waypoints.[]': () => this._updateOrAddComponent()
      });
      watched.forEach(({
        name,
        remove
      }) => this._eventListeners.set(name, remove));
      return this._super(...arguments);
    },
    /**
     * Fetch routing information from DirectionsService.
     *
     * @method route
     * @public
     */
    route(options) {
      return new _rsvp.Promise((resolve, reject) => {
        (0, _runloop.scheduleOnce)('afterRender', this, this._performRouteTask, options, resolve, reject);
      });
    },
    _performRouteTask(options, resolve, reject) {
      (0, _object.get)(this, 'routeTask').perform(options).then(result => resolve(result)).catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          reject(e);
        }
      });
    },
    routeTask: (0, _emberConcurrency.task)(function* (options) {
      let directions = yield (0, _object.get)(this, 'fetchDirections').perform(options);
      (0, _object.setProperties)(this, {
        directions,
        mapComponent: directions
      });
      if (this.onDirectionsChanged) {
        (0, _runloop.schedule)('afterRender', () => {
          var _this$onDirectionsCha;
          return (_this$onDirectionsCha = this.onDirectionsChanged) === null || _this$onDirectionsCha === void 0 ? void 0 : _this$onDirectionsCha.call(this, this.publicAPI);
        });
      }
      return directions;
    }).restartable(),
    fetchDirections: (0, _emberConcurrency.task)(function* (options) {
      let directionsService = yield (0, _object.get)(this, 'directionsService');
      let request = new _rsvp.Promise((resolve, reject) => {
        directionsService.route(options, (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      let directions = yield request;
      return directions;
    }),
    _registerWaypoint(waypoint) {
      (0, _runloop.schedule)('actions', () => {
        (0, _object.get)(this, 'waypoints').pushObject(waypoint);
      });
    },
    _unregisterWaypoint(waypoint) {
      (0, _runloop.schedule)('actions', () => {
        (0, _object.get)(this, 'waypoints').removeObject(waypoint);
      });
    }
  });
});