define("ember-models-table/components/models-table/row-select-checkbox", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/utils", "ember-models-table/templates/components/models-table/row-select-checkbox"], function (_exports, _component, _component2, _object, _utils, _rowSelectCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Component with checkbox to select or deselect any single row
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/row-select-checkbox',
   *     disableFiltering: true,
   *     mayBeHidden: false,
   *     componentForSortCell: 'models-table/row-select-all-checkbox'
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   * const data = [ ... ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @multipleSelect={{true}}
   * />
   * ```
   *
   * @class ModelsTableRowSelectCheckbox
   * @extends Ember.Component
   * @namespace Components
   */
  let RowSelectCheckboxComponent = _exports.default = (_dec = (0, _component.layout)(_rowSelectCheckbox.default), _dec(_class = (_class2 = class RowSelectCheckboxComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property column
       * @type Utils.ModelsTableColumn
       * @default null;
       */
      _defineProperty(this, "column", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @default null
       * @type object[]
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @default null
       * @type object[]
       */
      _defineProperty(this, "data", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @event toggleAllSelection
       */
      _defineProperty(this, "toggleAllSelection", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTable.clickOnRow](Components.ModelsTable.html#event_clickOnRow)
       *
       * @event clickOnRow
       */
      _defineProperty(this, "clickOnRow", null);
    }
    /**
     * @event doClickOnRow
     * @protected
     * @param {number} index
     * @param {object} record
     * @param {Event} e
     */
    doClickOnRow(index, record, e) {
      this.clickOnRow(index, record);
      (0, _utils.tryInvoke)(e, 'stopPropagation');
      return false;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "doClickOnRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doClickOnRow"), _class2.prototype)), _class2)) || _class);
});