define("ember-models-table/components/models-table/global-filter", ["exports", "@ember-decorators/component", "@ember/component", "ember-models-table/templates/components/models-table/global-filter", "@ember/object", "@ember/object/computed"], function (_exports, _component, _component2, _globalFilter, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Global filter element used within [models-table](Components.ModelsTable.html).
   *
   * Its value is used for all data-items and for each columns `propertyName`.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.GlobalFilter />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.GlobalFilter>
   *     {{input value=MT.globalFilter}}
   *     <button disabled={{if MT.globalFilterUsed "disabled"}} {{action (mut MT.globalFilter) ""}}>
   *       Clear Global Filter
   *     </button>
   *   </MT.GlobalFilter>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableGlobalFilter
   * @extends Ember.Component
   */
  let GlobalFilterComponent = _exports.default = (_dec = (0, _component.layout)(_globalFilter.default), _dec2 = (0, _computed.alias)('themeInstance.globalFilterWrapper'), _dec3 = (0, _object.computed)('elementId'), _dec(_class = (_class2 = class GlobalFilterComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property globalFilterWrapper
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "globalFilterWrapper", _descriptor, this);
      /**
       * Bound from [ModelsTable.filterString](Components.ModelsTable.html#property_filterString)
       *
       * @property value
       * @type string
       * @default null
       */
      _defineProperty(this, "value", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.globalFilterUsed](Components.ModelsTable.html#property_globalFilterUsed)
       *
       * @property globalFilterUsed
       * @type boolean
       * @default null
       */
      _defineProperty(this, "globalFilterUsed", null);
    }
    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-global-filter`;
    }

    /**
     * @event noop
     * @protected
     */
    noop() {}

    /**
     * @event updateGlobalFilterString
     * @protected
     * @param {Event} e
     */
    updateGlobalFilterString(e) {
      if (e) {
        e.stopPropagation();
      }
      (0, _object.set)(this, 'value', e.target.value);
      return false;
    }

    /**
     * @event clearGlobalFilter
     * @protected
     * @param {Event} e
     */
    clearGlobalFilter(e) {
      if (e) {
        e.stopPropagation();
      }
      (0, _object.set)(this, 'value', '');
      return false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "globalFilterWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateGlobalFilterString", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateGlobalFilterString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearGlobalFilter", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clearGlobalFilter"), _class2.prototype)), _class2)) || _class);
});