define("ember-models-table/components/models-table/grouped-header", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-models-table/templates/components/models-table/grouped-header"], function (_exports, _component, _component2, _object, _groupedHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Table header item used within [models-table/table-header](Components.ModelsTableTableHeader.html).
   *
   * Each `grouped-header` should represent one item from [ModelsTable.groupedHeaders](Components.ModelsTable.html#property_groupedHeaders).
   *
   * Usage example:
   *
   * ```js
   * const groupedHeaders = [
   *   [{title: 'BigTitle', colspan: 5}],
   *   [{title: 'SubTitle1', colspan: 2}, {title: 'SubTitle2', colspan: 3}]
   * ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @columns={{columns}}
   *   @data={{data}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       {{#each groupedHeaders as |groupedHeader|}}
   *         <Header.GroupedHeader @groupedHeader={{groupedHeader}} as |GroupedHeader|>
   *           {{#each GroupedHeader.groupedHeader as |cell|}}
   *             <th colspan={{cell.colspan}} rowspan={{cell.rowspan}}>{{cell.title}}</th>
   *           {{/each}}
   *         </Header.GroupedHeader>
   *       {{/each}}
   *     </Table.Header>
   *   </MT.Table>
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableGroupedHeader
   * @namespace Components
   * @extends Ember.Component
   */
  let GroupedHeaderComponent = _exports.default = (_dec = (0, _component.layout)(_groupedHeader.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _object.computed)('displayGroupedValueAs', 'useDataGrouping', 'visibleProcessedColumns.[]'), _dec(_class = _dec2(_class = (_class2 = class GroupedHeaderComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'tr'
       */
      /**
       * One of the [ModelsTable.groupedHeaders](Components.ModelsTable.html#property_groupedHeaders)
       *
       * @property groupedHeader
       * @type GroupedHeader[]
       * @default null
       */
      _defineProperty(this, "groupedHeader", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @default null
       * @type object
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.useDataGrouping](Components.ModelsTable.html#property_useDataGrouping)
       *
       * @property useDataGrouping
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useDataGrouping", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @default null
       * @type string
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @default null
       * @type {Utils.ModelsTableColumn[]}
       */
      _defineProperty(this, "visibleProcessedColumns", null);
    }
    /**
     * @property shouldAddExtraColumn
     * @type boolean
     * @default false
     * @protected
     */
    get shouldAddExtraColumn() {
      return this.displayGroupedValueAs === 'column' && this.useDataGrouping && !!this.visibleProcessedColumns.length;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "shouldAddExtraColumn", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAddExtraColumn"), _class2.prototype)), _class2)) || _class) || _class);
});