define("ember-composable-helpers/helpers/slice", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slice = slice;
  function slice([...args]) {
    let array = args.pop();
    if (!array) {
      array = [];
    }
    return array.slice(...args);
  }
  var _default = _exports.default = (0, _helper.helper)(slice);
});