define("ember-models-table/components/models-table/data-group-by-select", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-models-table/templates/components/models-table/data-group-by-select"], function (_exports, _component, _component2, _object, _computed, _dataGroupBySelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Dropdown to select property for table-rows grouping
   *
   * Also component allows to select sort order for property used for grouping
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @useDataGrouping={{true}}
   *   @currentGroupingPropertyName="firstName"
   *   @displayGroupedValueAs="column"
   *   @dataGroupProperties={{dataGroupProperties}} as |MT|>
   *   <MT.DataGroupBySelect />
   *   {{! ... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @useDataGrouping={{true}}
   *   @currentGroupingPropertyName="firstName"
   *   @displayGroupedValueAs="column"
   *   @dataGroupProperties={{dataGroupProperties}} as |MT|>
   *   <MT.DataGroupBySelect as |DGBS|>
   *     <label>{{MT.themeInstance.groupByLabelMsg}}</label>
   *     <DGBS.Select />
   *     <button
   *       class={{MT.themeInstance.sortGroupedPropertyBtn}}
   *       onclick={{action DGBS.sort}}>
   *       <i class={{if
   *        (is-equal MT.sortByGroupedFieldDirection "asc")
   *        MT.themeInstance.sortAscIcon
   *        MT.themeInstance.sortDescIcon}}>
   *       </i>
   *     </button>
   *   </MT.DataGroupBySelect>
   * </ModelsTable>
   * ```
   *
   * References to the following actions are yielded:
   *
   * * [sort](Components.ModelsTableDataGroupBySelect.html#event_doSort) - do sort by property name used to group rows
   *
   * @class ModelsTableDataGroupBySelect
   * @namespace Components
   * @extends Ember.Component
   */
  let DataGroupBySelectComponent = _exports.default = (_dec = (0, _component.layout)(_dataGroupBySelect.default), _dec2 = (0, _computed.alias)('themeInstance.dataGroupBySelectWrapper'), _dec(_class = (_class2 = class DataGroupBySelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property dataGroupBySelectWrapper
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "dataGroupBySelectWrapper", _descriptor, this);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property value
       * @type string|number|boolean
       * @default null
       */
      _defineProperty(this, "value", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.dataGroupOptions](Components.ModelsTable.html#property_dataGroupOptions)
       *
       * @property options
       * @default null
       * @type SelectOption[]
       */
      _defineProperty(this, "options", null);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property currentGroupingPropertyName
       * @type string
       * @default null
       */
      _defineProperty(this, "currentGroupingPropertyName", null);
      /**
       * Bound from [ModelsTable.sortByGroupedFieldDirection](Components.ModelsTable.html#property_sortByGroupedFieldDirection)
       *
       * @property sortByGroupedFieldDirection
       * @type string
       * @default null
       */
      _defineProperty(this, "sortByGroupedFieldDirection", null);
      /**
       * Closure action [ModelsTable.sort](Components.ModelsTable.html#event_sort)
       *
       * @event sort
       */
      _defineProperty(this, "sort", null);
    }
    /**
     * @event doSort
     * @protected
     */
    doSort() {
      this.sort({
        propertyName: this.currentGroupingPropertyName
      });
    }

    /**
     * @event noop
     * @protected
     */
    noop() {}
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "dataGroupBySelectWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "doSort", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype)), _class2)) || _class);
});