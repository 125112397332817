define("ember-models-table/components/models-table-server-paginated", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/runloop", "@ember/debug", "ember-models-table/components/models-table", "ember-models-table/templates/components/models-table"], function (_exports, _component, _object, _computed, _utils, _runloop, _debug, _modelsTable, _modelsTable2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Table-component with pagination, sorting and filtering.
   *
   * It should be used when pagination, filtering and sorting are done on the server-side. Otherwise [models-table](Components.ModelsTable.html) should be used.
   *
   * This component extends the base models-table component. For the end user, it can be used (nearly) the same:
   *
   * ```hbs
   * <ModelsTableServerPaginated @data={{data}} @columns={{columns}} />
   * ```
   *
   * Usage with block context:
   *
   * ```hbs
   * <ModelsTableServerPaginated @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.GlobalFilter />
   *   <MT.DataGroupBySelect />
   *   <MT.ColumnsDropdown />
   *   <MT.Table />
   *   <MT.Footer />
   * </ModelsTable>
   * ```
   *
   * ModelsTableServerPaginated yields same components, actions and properties as a ModelsTable does. Check its docs for more info.
   *
   * Check own docs for each component to get detailed info.
   *
   * ModelsTableServerPaginated has a lot of options you may configure, but there are two required properties called `data` and `columns`. First one contains data-query:
   *
   * ```js
   * model() {
   *  return this.store.query('my-model', {});
   * }
   * ```
   *
   * It will then take this query and extend it with pagination, sorting and filtering information. All other query parameters added in will remain untouched. Everything else works exactly the same - global filters, column filters etc. still use the same properties to control them. A few things to notice:
   *
   * - When using `filterWithSelect` for a column, you must use `predefinedFilterOptions`, because the automatic loading of possible filter values cannot work here.
   * - There is a new optional field `filteredBy` for columns, which works much like `sortedBy`: if set, this field will be used as query parameter, otherwise it will use the `propertyName`.
   * - Sorting will not use multipleColumnSorting, it will only sort by one column.
   * - If you set `sortedBy: false` on a column, sorting will be disabled for this column.
   *
   * There are a couple of things which can be configured to adapt to your API:
   *
   * ```js
   * // The property on meta to load the pages count from.
   * metaPagesCountProperty: 'pagesCount',
   *
   * // The property on meta to load the total item count from.
   * metaItemsCountProperty: 'itemsCount',
   *
   * // The time to wait until new data is actually loaded.
   * // This can be tweaked to avoid making too many server requests.
   * debounceDataLoadTime: 500,
   *
   * // The query parameters to use for server side filtering / querying.
   * filterQueryParameters: {
   *   globalFilter: 'search',
   *   sort: 'sort',
   *   sortDirection: 'sortDirection',
   *   page: 'page',
   *   pageSize: 'pageSize'
   * },
   * ```
   *
   * This default configuration would try to get the total page count from `model.get('meta.pagesCount')` and the total item count from `model.get('meta.itemsCount')`, and would then go on to build the following query:
   *
   * ```js
   * columns: [
   *   {
   *     propertyName: 'name',
   *     filteredBy: 'model_name'
   *   }
   * ]
   *
   * // after searching globally for "searchtexthere"
   * // and in the name column for "filterforname",
   * // and going to page 2,
   * // the following query would be built:
   * ?page=2&pageSize=50&search=searchtexthere&sort=name&sortDirection=ASC&model_name=filterforname
   * ```
   *
   * @class ModelsTableServerPaginated
   * @namespace Components
   * @extends Components.ModelsTable
   */
  let ModelsTableServerPaginated = _exports.default = (_dec = (0, _component.layout)(_modelsTable2.default), _dec2 = (0, _computed.alias)('data'), _dec3 = (0, _computed.alias)('arrangedContent'), _dec4 = (0, _computed.alias)('filteredContent'), _dec5 = (0, _object.computed)('filteredContent.meta', 'metaItemsCountProperty'), _dec6 = (0, _object.computed)('filteredContent.meta', 'metaPagesCountProperty'), _dec7 = (0, _object.computed)('pageSize', 'currentPageNumber', 'arrangedContentLength'), _dec(_class = (_class2 = class ModelsTableServerPaginated extends _modelsTable.default {
    constructor(...args) {
      super(...args);
      /**
       * True if data is currently being loaded from the server.
       * Can be used in the template to e.g. display a loading spinner.
       *
       * @protected
       * @property isLoading
       * @type boolean
       * @default false
       */
      _defineProperty(this, "isLoading", false);
      /**
       * True if last data query promise has been rejected.
       * Can be used in the template to e.g. indicate stale data or to e.g. show error state.
       *
       * @protected
       * @property isError
       * @type boolean
       * @default false
       */
      _defineProperty(this, "isError", false);
      /**
       * The property on meta to load the pages count from.
       *
       * @property metaPagesCountProperty
       * @type string
       * @default 'pagesCount'
       */
      _defineProperty(this, "metaPagesCountProperty", 'pagesCount');
      /**
       * The property on meta to load the total item count from.
       *
       * @property metaItemsCountProperty
       * @type string
       * @default 'itemsCount'
       */
      _defineProperty(this, "metaItemsCountProperty", 'itemsCount');
      /**
       * The time to wait until new data is actually loaded.
       * This can be tweaked to avoid making too many server requests.
       *
       * @property debounceDataLoadTime
       * @type number
       * @default 500
       */
      _defineProperty(this, "debounceDataLoadTime", 500);
      /**
       * Determines if multi-columns sorting should be used
       *
       * @property multipleColumnsSorting
       * @type boolean
       * @default false
       */
      _defineProperty(this, "multipleColumnsSorting", false);
      /**
       * The query parameters to use for server side filtering / querying.
       *
       * @property filterQueryParameters
       * @type object
       */
      _defineProperty(this, "filterQueryParameters", {
        globalFilter: 'search',
        sort: 'sort',
        sortDirection: 'sortDirection',
        page: 'page',
        pageSize: 'pageSize'
      });
      /**
       * @property observedProperties
       * @type string[]
       * @default ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'filterString', 'processedColumns.@each.filterString']
       * @protected
       */
      _defineProperty(this, "observedProperties", ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'filterString', 'processedColumns.@each.filterString']);
      /**
       * @property filteredContent
       * @default null
       * @protected
       * @type object[]
       */
      _initializerDefineProperty(this, "filteredContent", _descriptor, this);
      /**
       * For server side filtering, visibleContent is same as the filtered content
       *
       * @property visibleContent
       * @protected
       * @type object[]
       */
      _initializerDefineProperty(this, "visibleContent", _descriptor2, this);
      /**
       * For server side filtering, arrangedContent is same as the filtered content
       *
       * @property arrangedContent
       * @protected
       * @type object[]
       */
      _initializerDefineProperty(this, "arrangedContent", _descriptor3, this);
    }
    /**
     * The total content length is get from the meta information.
     * Set metaItemsCountProperty to change from which meta property this is loaded.
     *
     * @property arrangedContentLength
     * @type number
     * @protected
     */
    get arrangedContentLength() {
      const meta = this.filteredContent && this.filteredContent.meta ? this.filteredContent.meta : {};
      return (0, _object.get)(meta, this.metaItemsCountProperty) || 0;
    }

    /**
     * The pages count is get from the meta information.
     * Set metaPagesCountProperty to change from which meta property this is loaded.
     *
     * @property pagesCount
     * @type number
     * @protected
     */
    get pagesCount() {
      const meta = this.filteredContent && this.filteredContent.meta ? this.filteredContent.meta : {};
      return (0, _object.get)(meta, this.metaPagesCountProperty) || 1;
    }

    /**
     * The index of the last item that is currently being shown.
     *
     * @property lastIndex
     * @type number
     * @protected
     */
    get lastIndex() {
      let pageMax = parseInt(this.pageSize, 10) * this.currentPageNumber;
      return Math.min(pageMax, this.arrangedContentLength);
    }

    /**
     * This function actually loads the data from the server.
     * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
     *
     * @return Promise
     * @method _loadData
     * @private
     */
    _loadData() {
      const {
        data,
        currentPageNumber,
        pageSize,
        processedColumns: columns,
        sortProperties,
        filterString
      } = this;
      if (!data.query) {
        (true && (0, _debug.warn)('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data', false, {
          id: '#emt-query-usage'
        }));
        return;
      }
      let query = Object.assign({}, data.query);
      let store = data.store;
      let modelName = data.type.modelName;

      // Add pagination information
      query[this.filterQueryParameters.page] = currentPageNumber;
      query[this.filterQueryParameters.pageSize] = pageSize;
      // Add sorting information
      if (sortProperties && sortProperties.length) {
        if (this.multipleColumnsSorting) {
          query = this.multipleColumnsSortingWrapper(query, sortProperties);
        } else {
          if (sortProperties[0]) {
            let [sortBy, sortDirection] = sortProperties[0].split(':');
            query = this.singleColumnSortingWrapper(query, sortBy, sortDirection.toUpperCase());
          }
        }
      } else {
        delete query[[this.filterQueryParameters.sort]];
        delete query[[this.filterQueryParameters.sortDirection]];
      }

      // Add global filter
      let globalFilter = this.filterQueryParameters.globalFilter;
      if (filterString) {
        query[globalFilter] = filterString;
      } else {
        delete query[globalFilter];
      }

      // Add per-column filter
      if (this.useFilteringByColumns) {
        columns.forEach(column => {
          let filter = column.filterString;
          let filterTitle = this.getCustomFilterTitle(column);
          this.setQueryFilter(query, column, filterTitle, filter);
        });
      }
      (0, _object.setProperties)(this, {
        isLoading: true,
        isError: false
      });
      return this.doQuery(store, modelName, query).then(() => (0, _object.setProperties)(this, {
        isLoading: false,
        isError: false
      })).catch(() => (0, _object.setProperties)(this, {
        isLoading: false,
        isError: true
      }));
    }

    /**
     * Do query-request to load new data
     *
     * You may override this method to add some extra behavior or even additional requests
     *
     * @method doQuery
     * @param {object} store
     * @param {string} modelName
     * @param {object} query
     * @return Promise
     */
    doQuery(store, modelName, query) {
      return store.query(modelName, query).then(newData => (0, _object.set)(this, 'filteredContent', newData));
    }

    /**
     * Actually set the filter on a query.
     * This can be overwritten for special case handling.
     * Note that this will mutate the given query object!
     *
     * @param {object} query the query to mutate
     * @param {object} column the column that is filtering
     * @param {string} filterTitle the query param name for filtering
     * @param {*} filter the actual filter value
     * @method setQueryFilter
     */
    setQueryFilter(query, column, filterTitle, filter) {
      if (!(0, _utils.isBlank)(filter)) {
        query[filterTitle] = filter;
      } else {
        delete query[filterTitle];
      }
    }

    /**
     * Wrapper for sorting query when single column sorting is used
     *
     * @param {object} query parameters
     * @param {string} sortBy
     * @param {string} sortDirection
     * @return object query parameters
     * @method singleColumnSortingWrapper
     */
    singleColumnSortingWrapper(query, sortBy, sortDirection) {
      query[this.filterQueryParameters.sort] = sortBy;
      query[this.filterQueryParameters.sortDirection] = sortDirection;
      return query;
    }

    /**
     * Wrapper for sorting query when multi columns sorting is used
     *
     * @param {object} query
     * @param {object} sortProperties
     * @return object query parameters
     * @method multipleColumnsSortingWrapper
     */
    multipleColumnsSortingWrapper(query, sortProperties) {
      query[this.filterQueryParameters.sort] = sortProperties.map(sortProp => {
        const [prop, direction] = sortProp.split(':');
        const sign = direction.toLowerCase() === 'desc' ? '-' : '';
        return `${sign}${prop}`;
      }).join(',');
      return query;
    }

    /**
     * Customize filter title
     *
     * @method getCustomFilterTitle
     * @param {object} column
     * @return string title
     */
    getCustomFilterTitle(column) {
      return column.filteredBy || column.propertyName;
    }

    /**
     * @protected
     * @event sort
     * @param {Utils.ModelsTableColumn} column
     */
    sort(column) {
      let sortedBy = column.sortedBy || column.propertyName;
      if ((0, _utils.isNone)(sortedBy)) {
        return;
      }
      let currentSorting = column.sorting;
      let newSorting = this.sortMap[currentSorting.toLowerCase()];
      let sortingArgs = [column, sortedBy, newSorting];
      if (this.multipleColumnsSorting) {
        this._multiColumnsSorting(...sortingArgs);
      } else {
        this._singleColumnSorting(...sortingArgs);
      }
      this.userInteractionObserver();
    }
    _addPropertyObserver() {
      _runloop.run.debounce(this, this._loadData, this.debounceDataLoadTime);
    }
    willInsertElement() {
      super.willInsertElement(...arguments);
      this.observedProperties.forEach(propertyName => this.addObserver(propertyName, this, '_addPropertyObserver'));
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.observedProperties.forEach(propertyName => this.removeObserver(propertyName, this, '_addPropertyObserver'));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "filteredContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "visibleContent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "arrangedContent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "arrangedContentLength", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "arrangedContentLength"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pagesCount", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "pagesCount"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "lastIndex", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "lastIndex"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sort", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "sort"), _class2.prototype)), _class2)) || _class);
});