define("ember-models-table/utils/better-compare", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = betterCompare;
  /*
   * This is a better version of Ember.compare.
   * Sadly, Ember.compare() will always return 0 when comparing two instances of JavaScript objects that do not
   * implement the Comparable-mixin.
   * This function will compare instances via their `valueOf()` method if available.
   *
   * @param {Mixed} v
   * @param {Mixed} w
   * @return number
   */
  function betterCompare(v, w) {
    let type1 = (0, _utils.typeOf)(v);
    let type2 = (0, _utils.typeOf)(w);
    if (type1 === 'instance' && type2 === 'instance' || type1 === 'object' && type2 === 'object') {
      if ((0, _utils.typeOf)(v.compare) === 'function' && (0, _utils.typeOf)(w.compare) === 'function') {
        return v.compare(v, w);
      }
      if ((0, _utils.typeOf)(v.valueOf) === 'function' && (0, _utils.typeOf)(w.valueOf) === 'function') {
        return (0, _utils.compare)(v.valueOf(), w.valueOf());
      }
    }
    return (0, _utils.compare)(v, w);
  }
});