define("ember-models-table/components/models-table/pagination-simple", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-models-table/templates/components/models-table/pagination-simple"], function (_exports, _component, _component2, _object, _computed, _paginationSimple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Simple navigation (first, prev, next, last) used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationSimple />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationSimple as |PS|>
   *       <button
   *         class="{{if PS.goToBackEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToFirst}}>
   *         <i class={{MT.themeInstance.navFirstIcon}}></i>
   *       </button>
   *       <button
   *         class="{{if PS.goToBackEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToPrev}}>
   *         <i class={{MT.themeInstance.navPrevIcon}}></i>
   *       </button>
   *       <button
   *         class="{{if PS.goToForwardEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToNext}}>
   *         <i class={{MT.themeInstance.navNextIcon}}></i>
   *       </button>
   *       <button
   *         class="{{if PS.goToForwardEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToLast}}>
   *         <i class={{MT.themeInstance.navLastIcon}}></i>
   *       </button>
   *       <PS.PageNumberSelect/>
   *     </Footer.PaginationSimple>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * ModelsTablePaginationSimple yields references to the following contextual components:
   *
   * * PageNumberSelect - selectbox with list of available pages
   *
   * References to the following properties are yielded:
   *
   * * [goToBackEnabled](Components.ModelsTablePaginationSimple.html#property_goToBackEnabled) - `true` is user is not in the first page
   * * [goToForwardEnabled](Components.ModelsTablePaginationSimple.html#property_goToForwardEnabled) - `true` if user is not in the last page
   *
   * References to the following actions are yielded:
   *
   * * [goToPage](Components.ModelsTablePaginationSimple.html#event_goToPage) - action to navigate user to the custom page
   * * [goToFirst](Components.ModelsTablePaginationSimple.html#event_goToFirst) - action to navigate user to the first page
   * * [goToPrev](Components.ModelsTablePaginationSimple.html#event_goToPrev) - action to navigate user to the previous page
   * * [goToNext](Components.ModelsTablePaginationSimple.html#event_goToNext) - action to navigate user to the next page
   * * [goToLast](Components.ModelsTablePaginationSimple.html#event_goToLast) - action to navigate user to the last page
   *
   * @class ModelsTablePaginationSimple
   * @namespace Components
   * @extends Ember.Component
   */
  let PaginationSimpleComponent = _exports.default = (_dec = (0, _component.layout)(_paginationSimple.default), _dec2 = (0, _computed.alias)('themeInstance.paginationWrapper'), _dec3 = (0, _computed.alias)('themeInstance.paginationWrapperDefault'), _dec4 = (0, _computed.gt)('currentPageNumber', 1), _dec5 = (0, _object.computed)('currentPageNumber', 'pagesCount'), _dec6 = (0, _object.computed)('elementId'), _dec(_class = (_class2 = class PaginationSimpleComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property themePaginationWrapperClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themePaginationWrapperClass", _descriptor, this);
      /**
       * @property themePaginationWrapperDefaultClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themePaginationWrapperDefaultClass", _descriptor2, this);
      /**
       * Bound from [ModelsTable.currentPageNumber](Components.ModelsTable.html#property_currentPageNumber)
       *
       * @property currentPageNumber
       * @type number
       * @default 1
       */
      _defineProperty(this, "currentPageNumber", 1);
      /**
       * Bound from [ModelsTable.arrangedContentLength](Components.ModelsTable.html#property_arrangedContentLength)
       *
       * @property recordsCount
       * @type number
       * @default null
       */
      _defineProperty(this, "recordsCount", null);
      /**
       * Bound from [ModelsTable.pagesCount](Components.ModelsTable.html#property_pagesCount)
       *
       * @property pagesCount
       * @type number
       * @default null
       */
      _defineProperty(this, "pagesCount", null);
      /**
       * Bound from [ModelsTable.currentPageNumberOptions](Components.ModelsTable.html#property_currentPageNumberOptions)
       *
       * @property currentPageNumberOptions
       * @type SelectOption[]
       * @default null
       */
      _defineProperty(this, "currentPageNumberOptions", null);
      /**
       * Bound from [ModelsTable.showCurrentPageNumberSelect](Components.ModelsTable.html#property_showCurrentPageNumberSelect)
       *
       * @property showCurrentPageNumberSelect
       * @type boolean
       * @default null
       */
      _defineProperty(this, "showCurrentPageNumberSelect", null);
      /**
       * Closure action [ModelsTable.gotoCustomPage](Components.ModelsTable.html#event_gotoCustomPage)
       *
       * @event goToPage
       */
      _defineProperty(this, "goToPage", null);
      /**
       * Bound from [ModelsTable.pageSize](Components.ModelsTable.html#property_pageSize)
       *
       * @property pageSize
       * @type number
       * @default 10
       */
      _defineProperty(this, "pageSize", 10);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Are buttons "Back" and "First" enabled
       *
       * @property goToBackEnabled
       * @type boolean
       * @default false
       * @protected
       */
      _initializerDefineProperty(this, "goToBackEnabled", _descriptor3, this);
    }
    /**
     * Are buttons "Next" and "Last" enabled
     *
     * @property goToForwardEnabled
     * @type boolean
     * @default false
     * @protected
     */
    get goToForwardEnabled() {
      return this.currentPageNumber < this.pagesCount;
    }
    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-page-number-select`;
    }

    /**
     * @event gotoFirst
     * @param {Event} e
     * @protected
     */
    gotoFirst(e) {
      if (e) {
        e.stopPropagation();
      }
      if (!this.goToBackEnabled) {
        return false;
      }
      this.goToPage(1);
      return false;
    }

    /**
     * @event gotoPrev
     * @param {Event} e
     * @protected
     */
    gotoPrev(e) {
      if (e) {
        e.stopPropagation();
      }
      if (!this.goToBackEnabled) {
        return false;
      }
      if (this.currentPageNumber > 1) {
        this.goToPage(this.currentPageNumber - 1);
      }
      return false;
    }

    /**
     * @event gotoNext
     * @param {Event} e
     * @protected
     */
    gotoNext(e) {
      if (e) {
        e.stopPropagation();
      }
      if (!this.goToForwardEnabled) {
        return false;
      }
      const pageSize = parseInt(this.pageSize, 10);
      if (this.recordsCount > pageSize * (this.currentPageNumber - 1)) {
        this.goToPage(this.currentPageNumber + 1);
      }
      return false;
    }

    /**
     * @event gotoLast
     * @param {Event} e
     * @protected
     */
    gotoLast(e) {
      if (e) {
        e.stopPropagation();
      }
      if (!this.goToForwardEnabled) {
        return;
      }
      const pageSize = parseInt(this.pageSize, 10);
      let pageNumber = this.recordsCount / pageSize;
      pageNumber = 0 === pageNumber % 1 ? pageNumber : Math.floor(pageNumber) + 1;
      this.goToPage(pageNumber);
      return false;
    }

    /**
     * @event gotoPage
     * @param {number} pageNumber
     * @protected
     */
    gotoPage(pageNumber) {
      this.goToPage(pageNumber);
    }

    /**
     * @event noop
     * @protected
     */
    noop() {}
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "themePaginationWrapperClass", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "themePaginationWrapperDefaultClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "goToBackEnabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "goToForwardEnabled", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "goToForwardEnabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gotoFirst", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoFirst"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gotoPrev", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoPrev"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gotoNext", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoNext"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gotoLast", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoLast"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gotoPage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype)), _class2)) || _class);
});