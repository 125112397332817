define("ember-wistia/components/wistia-video", ["exports", "ember", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop", "@ember/debug", "ember-wistia/templates/components/wistia-video"], function (_exports, _ember, _component, _object, _service, _runloop, _debug, _wistiaVideo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Logger
  } = _ember.default;
  var _default = _exports.default = _component.default.extend({
    layout: _wistiaVideo.default,
    matcher: null,
    email: null,
    wistia: (0, _service.inject)(),
    classNames: ['video-wrapper'],
    classNameBindings: ['isPlaying'],
    hideVideo: false,
    videoInitialize() {},
    isPlaying: (0, _object.computed)('matcher', function () {
      let wistia = (0, _object.get)(this, 'wistia');
      return wistia.getCurrentlyPlaying() === (0, _object.get)(this, 'matcher');
    }),
    init() {
      this._super(...arguments);
      let wistia = (0, _object.get)(this, 'wistia');
      let email = (0, _object.get)(this, 'email');
      let matcher = (0, _object.get)(this, 'matcher');
      (0, _object.set)(this, 'currentMatcher', matcher);
      wistia.addVideo(matcher, email);
    },
    didReceiveAttrs() {
      (true && !((0, _object.get)(this, 'matcher')) && (0, _debug.assert)('You have not passed in a Wistia matcher', (0, _object.get)(this, 'matcher')));
      this._super(...arguments);
    },
    didUpdateAttrs() {
      let matcher = (0, _object.get)(this, 'matcher');
      if (matcher !== (0, _object.get)(this, 'currentMatcher')) {
        this._rerenderWistiaVideo(matcher);
      }
      this._super(...arguments);
    },
    didRender() {
      let videoInitialize = (0, _object.get)(this, 'videoInitialize');
      let wistia = (0, _object.get)(this, 'wistia');
      let matcher = (0, _object.get)(this, 'matcher');
      wistia.getVideo(matcher).then(video => {
        videoInitialize(video, matcher);
      }).catch(error => {
        Logger.log(error.msg);
      });
    },
    _rerenderWistiaVideo(newMatcher) {
      (0, _object.set)(this, 'currentMatcher', newMatcher);
      (0, _object.set)(this, 'hideVideo', true);
      (0, _runloop.next)(() => {
        (0, _object.set)(this, 'hideVideo', false);
      });
    }
  });
});