define("ember-models-table/components/models-table/columns-hidden", ["exports", "@ember-decorators/component", "@ember/component", "ember-models-table/templates/components/models-table/columns-hidden"], function (_exports, _component, _component2, _columnsHidden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Table row used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * Component used when all columns are hidden and no data items are shown. Usually it contains a simple message.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.allColumnsAreHidden}}
   *         <Body.ColumnsHidden as |CH|>
   *           Any message here
   *         </Body.ColumnsHidden>
   *       {{else}}
   *         {{! ... }}
   *       {{/if}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableColumnsHidden
   * @namespace Components
   * @extends Ember.Component
   */
  let ColumnsHiddenComponent = _exports.default = (_dec = (0, _component.layout)(_columnsHidden.default), _dec2 = (0, _component.tagName)('tr'), _dec(_class = _dec2(_class = class ColumnsHiddenComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'tr'
       */
      /**
       * Bound from [ModelsTable.columnsCount](Components.ModelsTableTable.html#property_columnsCount)
       *
       * @property columnsCount
       * @type number
       * @default null
       */
      _defineProperty(this, "columnsCount", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
  }) || _class) || _class);
});