define("ember-models-table/components/models-table/expand-toggle", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-models-table/templates/components/models-table/expand-toggle"], function (_exports, _component, _component2, _object, _expandToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Component to expand or collapse any single row
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/expand-toggle',
   *     mayBeHidden: false
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   *
   * const data = [ ... ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @expandedRowComponent={{component "path/to/your-component"}}
   * />
   * ```
   *
   * @class ModelsTableExpandToggle
   * @extends Ember.Component
   * @namespace Components
   */
  let ExpandToggleComponent = _exports.default = (_dec = (0, _component.layout)(_expandToggle.default), _dec(_class = (_class2 = class ExpandToggleComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * One of the [data](Components.ModelsTable.html#property_data)
       *
       * @default null
       * @property record
       * @type object
       */
      _defineProperty(this, "record", null);
      /**
       * Row's index where current cell is
       *
       * @property index
       * @default null
       * @type number
       */
      _defineProperty(this, "index", null);
      /**
       * @property column
       * @default null
       * @type Utils.ModelsTableColumn
       */
      _defineProperty(this, "column", null);
      /**
       * @property isEditRow
       * @default null
       * @protected
       * @type boolean
       */
      _defineProperty(this, "isEditRow", null);
      /**
       * @property groupedLength
       * @type number
       * @default null
       */
      _defineProperty(this, "groupedLength", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTableRow.editRow](Components.ModelsTableRow.html#event_editRow)
       *
       * @event editRow
       */
      _defineProperty(this, "editRow", null);
      /**
       * Closure action [ModelsTableRow.saveRow](Components.ModelsTableRow.html#event_saveRow)
       *
       * @event saveRow
       */
      _defineProperty(this, "saveRow", null);
      /**
       * Closure action [ModelsTableRow.cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow)
       *
       * @event cancelEditRow
       */
      _defineProperty(this, "cancelEditRow", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Is current row expanded or not
       *
       * @default null
       * @property isExpanded
       * @type boolean
       */
      _defineProperty(this, "isExpanded", null);
      /**
       * Is current row selected or not
       *
       * @default null
       * @property isSelected
       * @type boolean
       */
      _defineProperty(this, "isSelected", null);
      /**
       * @property isColumnEditable
       * @type boolean
       * @default false
       */
      _defineProperty(this, "isColumnEditable", false);
    }
    /**
     * @event doCollapseRow
     * @param {number} index
     * @param {object} record
     * @param {Event} e
     * @protected
     */
    doCollapseRow(index, record, e) {
      this.collapseRow(index, record);
      e.stopPropagation();
      return false;
    }

    /**
     * @event doExpandRow
     * @param {number} index
     * @param {object} record
     * @param {Event} e
     * @protected
     */
    doExpandRow(index, record, e) {
      this.expandRow(index, record);
      e.stopPropagation();
      return false;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "doCollapseRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doCollapseRow"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doExpandRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doExpandRow"), _class2.prototype)), _class2)) || _class);
});