define("ember-google-maps/components/g-map", ["exports", "@ember/component", "ember-google-maps/templates/components/g-map", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers", "@ember/service", "@ember/application", "@ember/object", "@ember/object/computed", "@ember/object/internals", "@ember/array", "rsvp", "@ember/runloop", "ember-concurrency"], function (_exports, _component, _gMap, _optionsAndEvents, _helpers, _service, _application, _object, _computed, _internals, _array, _rsvp, _runloop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function safeScheduleOnce(queue, context, onSuccess, onError) {
    function func() {
      if (context.isDestroying || context.isDestroyed) {
        onError.call(context);
      } else {
        onSuccess.call(context);
      }
    }
    (0, _runloop.scheduleOnce)(queue, context, func);
  }
  function skipErrorReporting() {}
  function GMapAPI(source) {
    return {
      get id() {
        return (0, _object.get)(source, 'mapId');
      },
      get map() {
        return source.map;
      },
      get components() {
        return source.components;
      },
      actions: {
        update: () => source._updateMap(),
        trigger: () => source._trigger()
      }
    };
  }

  /**
   * @class GMap
   * @module ember-google-maps/components/g-map
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * @property googleMapsApi
     * @type GoogleMapsApi
     * @readOnly
     */
    googleMapsApi: (0, _service.inject)(),
    fastboot: (0, _object.computed)(function () {
      let owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }),
    isFastBoot: (0, _computed.reads)('fastboot.isFastBoot'),
    layout: _gMap.default,
    tagName: '',
    /**
     * Zoom level for the map
     *
     * @property zoom
     * @type {Number}
     * @default 8
     * @public
     */
    zoom: 8,
    /**
     * The latitude and longitude of the center of the map.
     *
     * @property center
     * @type {google.maps.LatLng}
     * @public
     */
    center: (0, _object.computed)('lat', 'lng', _helpers.position),
    google: (0, _computed.reads)('googleMapsApi.google'),
    mapComponent: (0, _computed.reads)('map'),
    /**
     * A unique id for the current map instance.
     *
     * @property mapId
     * @type {String}
     * @public
     */
    mapId: (0, _object.computed)(function () {
      return `ember-google-map-${(0, _internals.guidFor)(this)}`;
    }),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)(),
    _options: (0, _computed.readOnly)('_optionsAndEvents.options'),
    _events: (0, _computed.readOnly)('_optionsAndEvents.events'),
    _createOptions(options) {
      return _objectSpread(_objectSpread({}, options), {}, {
        center: (0, _object.get)(this, 'center'),
        zoom: (0, _object.get)(this, 'zoom')
      });
    },
    _createEvents(events) {
      return events;
    },
    init() {
      this._super(...arguments);
      this.components = {};
      this.gMap = {};
      this.publicAPI = GMapAPI(this);
      this._internalAPI = {
        _registerCanvas: this._registerCanvas.bind(this),
        _registerComponent: this._registerComponent.bind(this),
        _unregisterComponent: this._unregisterComponent.bind(this)
      };
      this._canvasIsRendering = (0, _rsvp.defer)();
      this._eventListeners = new Map();
      if (!(0, _object.get)(this, 'isFastBoot')) {
        (0, _object.get)(this, '_initMap').perform();
      }
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'map')) {
        let options = this._createOptions((0, _object.get)(this, '_options'));
        this._updateMap(options);
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      this._eventListeners.forEach(remove => remove());
    },
    /**
     * Initialize the map, register events and prep internal components.
     *
     * @method _initMap
     * @private
     * @return
     */
    _initMap: (0, _emberConcurrency.task)(function* () {
      yield (0, _object.get)(this, 'google');

      // After google loads, we need to wait for Ember to update any values read
      // directly from the google object and used in templates. For example, map
      // controls may be positioned with `ControlPosition.TOP_LEFT`, the value of
      // which is only available once google loads.
      yield this._waitForNextRunloop();
      let canvas = yield this._canvasIsRendering.promise;
      let options = this._createOptions((0, _object.get)(this, '_options'));
      let map = new google.maps.Map(canvas, options);
      function waitForComponents() {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this._waitForComponents().then(() => {
          var _this$onComponentsLoa;
          this._componentsInitialized = true;
          (_this$onComponentsLoa = this.onComponentsLoad) === null || _this$onComponentsLoa === void 0 ? void 0 : _this$onComponentsLoa.call(this, this.publicAPI);
        });
      }
      function setupMap() {
        var _this$onLoad;
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        (0, _object.set)(this, 'map', map);
        let payload = {
          map: this.map,
          publicAPI: this.publicAPI
        };
        (0, _optionsAndEvents.addEventListeners)(map, this._createEvents((0, _object.get)(this, '_events')), payload).forEach(({
          name,
          remove
        }) => this._eventListeners.set(name, remove));
        (_this$onLoad = this.onLoad) === null || _this$onLoad === void 0 ? void 0 : _this$onLoad.call(this, this.publicAPI);
        safeScheduleOnce('afterRender', this, waitForComponents, skipErrorReporting);
      }
      google.maps.event.addListenerOnce(map, 'idle', (0, _runloop.bind)(this, setupMap));
    }),
    _waitForComponents() {
      let componentsAreInitialized = Object.keys(this.components).map(name => this.components[name]).reduce((array, componentGroup) => array.concat(componentGroup), []).map(components => (0, _object.get)(components, 'isInitialized.promise'));
      return (0, _rsvp.all)(componentsAreInitialized);
    },
    _waitForNextRunloop() {
      return new Promise(resolve => {
        safeScheduleOnce('actions', this, resolve, skipErrorReporting);
      });
    },
    /**
     * Update the map options.
     *
     * @method _updateMap
     * @return
     */
    _updateMap(options) {
      (0, _object.get)(this, 'map').setOptions(options);
    },
    /**
     * Helper method to trigger Google Maps events.
     *
     * @method _trigger
     * @param {String} event Event name
     * @return
     */
    _trigger(...args) {
      google.maps.event.trigger((0, _object.get)(this, 'map'), ...args);
    },
    _registerCanvas(canvas) {
      (0, _object.set)(this, 'canvas', canvas);
      this._canvasIsRendering.resolve(canvas);
    },
    /**
     * Register a contextual component with the map component.
     *
     * @method _registerComponent
     * @param {String} type Plural name of the component
     * @param {Object} componentAPI
     * @return
     */
    _registerComponent(type, componentAPI) {
      this.components[type] = this.components[type] || (0, _array.A)();
      this.components[type].pushObject(componentAPI);
    },
    /**
     * Unregister a contextual component with the map component.
     *
     * @method _unregisterComponent
     * @param {String} type Name of the component
     * @param {Object} componentAPI
     * @return
     */
    _unregisterComponent(type, componentAPI) {
      this.components[type].removeObject(componentAPI);
    },
    _updateGMap(...props) {
      (0, _object.setProperties)(this.gMap, Object.assign({}, ...props));
    }
  });
});