define("ember-models-table/components/models-table/cell-column-summary", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-models-table/templates/components/models-table/cell-column-summary"], function (_exports, _component, _component2, _object, _cellColumnSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function sumBy(collection) {
    return (0, _object.computed)(`${collection}.[]`, function () {
      const c = (0, _object.get)(this, collection);
      return c ? c.reduce((a, b) => a + b, 0) : 0;
    });
  }
  function avgBy(collection, sumBy) {
    return (0, _object.computed)(sumBy, function () {
      const count = (0, _object.get)(this, `${collection}.length`);
      return count ? (0, _object.get)(this, sumBy) / count : 0;
    });
  }
  function minBy(collection) {
    return (0, _object.computed)(`${collection}.[]`, function () {
      return Math.min.apply(Math, (0, _object.get)(this, collection));
    });
  }
  function maxBy(collection) {
    return (0, _object.computed)(`${collection}.[]`, function () {
      return Math.max.apply(Math, (0, _object.get)(this, collection));
    });
  }
  function medianBy(collection) {
    return (0, _object.computed)(`${collection}.[]`, function () {
      let c = (0, _object.get)(this, collection);
      if (!(0, _object.get)(c, 'length')) {
        return null;
      }
      c = c.slice().sort((a, b) => a - b);
      let lowMiddle = Math.floor((c.length - 1) / 2);
      let highMiddle = Math.ceil((c.length - 1) / 2);
      return (c[lowMiddle] + c[highMiddle]) / 2;
    });
  }

  /**
   * Component for table-footer cells. Used as column-summary.
   *
   * It yields several properties:
   *
   * * [minSelected](Components.ModelsTableCellColumnSummary.html#property_minSelected) - min of selected items
   * * [maxSelected](Components.ModelsTableCellColumnSummary.html#property_maxSelected) - max of selected items
   * * [sumSelected](Components.ModelsTableCellColumnSummary.html#property_sumSelected) - sum of selected items
   * * [avgSelected](Components.ModelsTableCellColumnSummary.html#property_avgSelected) - average of selected items
   * * [medianSelected](Components.ModelsTableCellColumnSummary.html#property_medianSelected) - median of selected items
   * * [minData](Components.ModelsTableCellColumnSummary.html#property_minData) - min of data
   * * [maxData](Components.ModelsTableCellColumnSummary.html#property_maxData) - max of data
   * * [sumData](Components.ModelsTableCellColumnSummary.html#property_sumData) - sum of data
   * * [avgData](Components.ModelsTableCellColumnSummary.html#property_avgData) - average of data
   * * [medianData](Components.ModelsTableCellColumnSummary.html#property_medianData) - median of data
   *
   * Here `selectedItems` and `data` are bound from `models-table` and are mapped by `column.propertyName`.
   *
   * Component should be used only for column with set `propertyName`.
   *
   * Component should be extended or its template should be overridden.
   *
   * @namespace Components
   * @class ModelsTableCellColumnSummary
   * @extends Ember.Component
   */
  let CellColumnSummaryComponent = _exports.default = (_dec = (0, _component.layout)(_cellColumnSummary.default), _dec2 = (0, _component.tagName)('td'), _dec3 = minBy('mappedSelectedItems'), _dec4 = minBy('mappedData'), _dec5 = maxBy('mappedSelectedItems'), _dec6 = maxBy('mappedData'), _dec7 = sumBy('mappedSelectedItems'), _dec8 = sumBy('mappedData'), _dec9 = avgBy('mappedSelectedItems', 'sumSelected'), _dec10 = avgBy('mappedData', 'sumData'), _dec11 = medianBy('mappedSelectedItems'), _dec12 = medianBy('mappedData'), _dec(_class = _dec2(_class = (_class2 = class CellColumnSummaryComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'td'
       */
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @type object[]
       * @default null
       */
      _defineProperty(this, "data", null);
      /**
       * `selectedItems.mapBy(column.propertyName)`
       *
       * @property mappedSelectedItems
       * @default []
       * @type array
       */
      _defineProperty(this, "mappedSelectedItems", []);
      /**
       * `expandedItems.mapBy(column.propertyName)`
       *
       * @property mappedExpandedItems
       * @default []
       * @type array
       */
      _defineProperty(this, "mappedExpandedItems", []);
      /**
       * `data.mapBy(column.propertyName)`
       *
       * @property mappedData
       * @default []
       * @type object[]
       */
      _defineProperty(this, "mappedData", []);
      /**
       * @property minSelected
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "minSelected", _descriptor, this);
      /**
       * @property minData
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "minData", _descriptor2, this);
      /**
       * @property maxSelected
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "maxSelected", _descriptor3, this);
      /**
       * @property maxData
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "maxData", _descriptor4, this);
      /**
       * @property sumSelected
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "sumSelected", _descriptor5, this);
      /**
       * @property sumData
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "sumData", _descriptor6, this);
      /**
       * @property avgSelected
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "avgSelected", _descriptor7, this);
      /**
       * @property avgData
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "avgData", _descriptor8, this);
      /**
       * @property medianSelected
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "medianSelected", _descriptor9, this);
      /**
       * @property medianData
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "medianData", _descriptor10, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "minSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "minData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "maxSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "maxData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "sumSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "sumData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "avgSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "avgData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "medianSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "medianData", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
});