define("ember-time-field/utils/pad", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = pad;
  function pad(val) {
    const str = String(val);
    if (str.length === 1) {
      return `0${str}`;
    } else {
      return str;
    }
  }
});