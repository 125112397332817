define("ember-models-table/components/models-table/cell-content-edit", ["exports", "@ember-decorators/component", "@ember/component", "ember-models-table/templates/components/models-table/cell-content-edit", "@ember/object"], function (_exports, _component, _component2, _cellContentEdit, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Component for cells in the edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * @namespace Components
   * @class ModelsTableCellContentEdit
   * @extends Ember.Component
   */
  let CellContentEditComponent = _exports.default = (_dec = (0, _component.layout)(_cellContentEdit.default), _dec(_class = class CellContentEditComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * One of the [data](Components.ModelsTable.html#property_data)
       *
       * @default null
       * @property record
       * @type object
       */
      _defineProperty(this, "record", null);
      /**
       * Row's index where current cell is
       *
       * @property index
       * @default null
       * @type number
       */
      _defineProperty(this, "index", null);
      /**
       * @property column
       * @default null
       * @type Utils.ModelsTableColumn
       */
      _defineProperty(this, "column", null);
      /**
       * @property isEditRow
       * @default null
       * @protected
       * @type boolean
       */
      _defineProperty(this, "isEditRow", null);
      /**
       * @property groupedLength
       * @type number
       * @default null
       */
      _defineProperty(this, "groupedLength", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTableRow.editRow](Components.ModelsTableRow.html#event_editRow)
       *
       * @event editRow
       */
      _defineProperty(this, "editRow", null);
      /**
       * Closure action [ModelsTableRow.saveRow](Components.ModelsTableRow.html#event_saveRow)
       *
       * @event saveRow
       */
      _defineProperty(this, "saveRow", null);
      /**
       * Closure action [ModelsTableRow.cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow)
       *
       * @event cancelEditRow
       */
      _defineProperty(this, "cancelEditRow", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Is current row expanded or not
       *
       * @default null
       * @property isExpanded
       * @type boolean
       */
      _defineProperty(this, "isExpanded", null);
      /**
       * Is current row selected or not
       *
       * @default null
       * @property isSelected
       * @type boolean
       */
      _defineProperty(this, "isSelected", null);
      /**
       * @property isColumnEditable
       * @type boolean
       * @default false
       */
      _defineProperty(this, "isColumnEditable", false);
    }
    init() {
      if (this.themeInstance) {
        (0, _object.set)(this, 'tagName', this.themeInstance.cellContentTagName);
      }
      super.init(...arguments);
    }
  }) || _class);
});