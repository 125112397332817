define("ember-table/components/ember-th/resize-handle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bUG9eqt4",
    "block": "[[[41,[30,0,[\"isResizable\"]],[[[1,\"    \"],[10,0],[14,\"data-test-resize-handle\",\"\"],[14,0,\"et-header-resize-area\"],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "ember-table/components/ember-th/resize-handle/template.hbs",
    "isStrictMode": false
  });
});