define("ember-models-table/themes/ember-paper", ["exports", "ember-models-table/themes/default"], function (_exports, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @class EmberPaperTheme
   * @namespace Themes
   * @extends Themes.DefaultTheme
   */
  class EmberPaperTheme extends _default.default {
    constructor(...args) {
      super(...args);
      /**
       * @property columnsDropdownComponent
       * @type string
       * @default ''models-table/themes/ember-paper/columns-dropdown'
       */
      /**
       * @property dataGroupBySelectComponent
       * @type string
       * @default 'models-table/themes/ember-paper/data-group-by-select'
       */
      /**
       * @property globalFilterComponent
       * @type string
       * @default 'models-table/themes/ember-paper/global-filter'
       */
      /**
       * @property pageSizeSelectComponent
       * @type string
       * @default 'models-table/themes/ember-paper/page-size-select'
       */
      /**
       * @property paginationNumericComponent
       * @type string
       * @default 'models-table/themes/ember-paper/pagination-numeric'
       */
      /**
       * @property paginationSimpleComponent
       * @type string
       * @default 'models-table/themes/ember-paper/pagination-simple'
       */
      /**
       * @property rowFilteringCellComponent
       * @type string
       * @default 'models-table/themes/ember-paper/row-filtering-cell'
       */
      /**
       * @property rowSortingCellComponent
       * @type string
       * @default 'models-table/themes/ember-paper/row-sorting-cell'
       */
      /**
       * @property selectComponent
       * @type string
       * @default 'models-table/themes/ember-paper/select'
       */
      /**
       * @property summaryComponent
       * @type string
       * @default 'models-table/themes/ember-paper/summary'
       */
      /**
       * @property rowSelectAllCheckboxComponent
       * @type string
       * @default 'models-table/themes/ember-paper/row-select-all-checkbox'
       */
      /**
       * @property rowSelectCheckboxComponent
       * @type string
       * @default 'models-table/themes/ember-paper/row-select-checkbox'
       */
      /**
       * @property expandToggleComponent
       * @type string
       * @default 'models-table/themes/ember-paper/expand-toggle'
       */
      /**
       * @property cellEditToggleComponent
       * @type string
       * @default 'models-table/themes/ember-paper/cell-edit-toggle'
       */
      /**
       * @property cellContentEditComponent
       * @type string
       * @default 'models-table/themes/ember-paper/cell-content-edit'
       */
      /**
       * @property componentsPath
       * @type string
       * @default 'models-table/themes/ember-paper/'
       */
      _defineProperty(this, "componentsPath", 'models-table/themes/ember-paper/');
      /**
       * @property table
       * @type string
       * @default 'paper-table'
       */
      _defineProperty(this, "table", 'paper-table');
      /**
       * @property headerWrapper
       * @type string
       * @default 'layout-row layout-align-space-between'
       */
      _defineProperty(this, "headerWrapper", 'layout-row layout-align-space-between');
      /**
       * @property tfooterInternalWrapper
       * @type string
       * @default 'layout-row layout-align-space-between-center footer-internal-wrapper'
       */
      _defineProperty(this, "tfooterInternalWrapper", 'layout-row layout-align-space-between-center footer-internal-wrapper');
      /**
       * @property paginationInternalWrapper
       * @type string
       * @default 'layout-row layout-align-space-between-center'
       */
      _defineProperty(this, "paginationInternalWrapper", 'layout-row layout-align-space-between-center');
      /**
       * @property columnVisibleIcon
       * @type string
       * @default 'check_box'
       */
      _defineProperty(this, "columnVisibleIcon", 'check_box');
      /**
       * @property columnHiddenIcon
       * @type string
       * @default 'check_box_outline_blank'
       */
      _defineProperty(this, "columnHiddenIcon", 'check_box_outline_blank');
      /**
       * @property sortAscIcon
       * @type string
       * @default 'arrow_drop_up'
       */
      _defineProperty(this, "sortAscIcon", 'arrow_drop_up');
      /**
       * @property sortDescIcon
       * @type string
       * @default 'arrow_drop_down'
       */
      _defineProperty(this, "sortDescIcon", 'arrow_drop_down');
      /**
       * @property navFirstIcon
       * @type string
       * @default 'first_page'
       */
      _defineProperty(this, "navFirstIcon", 'first_page');
      /**
       * @property navPrevIcon
       * @type string
       * @default 'chevron_left'
       */
      _defineProperty(this, "navPrevIcon", 'chevron_left');
      /**
       * @property navNextIcon
       * @type string
       * @default 'chevron_right'
       */
      _defineProperty(this, "navNextIcon", 'chevron_right');
      /**
       * @property navLastIcon
       * @type string
       * @default 'last_page'
       */
      _defineProperty(this, "navLastIcon", 'last_page');
      /**
       * @property clearAllFiltersIcon
       * @type string
       * @default 'clear'
       */
      _defineProperty(this, "clearAllFiltersIcon", 'clear');
      /**
       * @property filteringCellInternalWrapper
       * @type string
       * @default 'layout-row layout-align-space-between-center'
       */
      _defineProperty(this, "filteringCellInternalWrapper", 'layout-row layout-align-space-between-center');
      /**
       * @property columnsDropdownWrapper
       * @type string
       * @default 'dropdown'
       */
      _defineProperty(this, "columnsDropdownWrapper", 'columns-dropdown');
      /**
       * @property collapseRowIcon
       * @type string
       * @default 'expand_less'
       */
      _defineProperty(this, "collapseRowIcon", 'expand_less');
      /**
       * @property expandRowIcon
       * @type string
       * @default 'expand_more'
       */
      _defineProperty(this, "expandRowIcon", 'expand_more');
      /**
       * @property collapseAllRowsIcon
       * @type string
       * @default 'expand_less'
       */
      _defineProperty(this, "collapseAllRowsIcon", 'expand_less');
      /**
       * @property expandAllRowsIcon
       * @type string
       * @default 'expand_more'
       */
      _defineProperty(this, "expandAllRowsIcon", 'expand_more');
    }
  }
  _exports.default = EmberPaperTheme;
});