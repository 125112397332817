define("ember-models-table/helpers/exists-in", ["exports", "@ember/component/helper", "@ember/array", "@ember/object", "@ember-decorators/object"], function (_exports, _helper, _array, _object, _object2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ExistsInEmberObject = _exports.default = (_dec = (0, _object.computed)('needle', 'haystack.[]'), _dec2 = (0, _object2.observes)('content'), (_class = class ExistsInEmberObject extends _helper.default {
    get content() {
      const {
        needle,
        haystack
      } = this;
      return (0, _array.isArray)(haystack) ? haystack.includes(needle) : false;
    }
    compute([haystack, needle]) {
      (0, _object.set)(this, 'needle', needle);
      (0, _object.set)(this, 'haystack', haystack);
      return this.content;
    }
    contentDidChange() {
      this.recompute();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "content", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "content"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contentDidChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "contentDidChange"), _class.prototype)), _class));
});