define("ember-google-maps/components/-private-api/warn-missing-component", ["exports", "@ember/component", "@ember/debug"], function (_exports, _component, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Throw an assertion to warn the user when they call a component that isn't
   * included in the build. In development, we replace the excluded component with
   * this one instead.
   */
  var _default = _exports.default = _component.default.extend({
    init() {
      this._super(...arguments);
      let name = this._name;
      let message = `
Ember Google Maps couldn't find a map component called "${name}"!

If you are excluding certain map components from your app in your ember-cli-build.js, make sure to
include "${name}".

Learn more at: https://ember-google-maps.sandydoo.me/docs/getting-started`;
      (true && !(false) && (0, _debug.assert)(message));
    }
  });
});