define("ember-models-table/components/models-table/row-sorting", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-models-table/templates/components/models-table/row-sorting", "ember-models-table/utils/macros", "ember-models-table/utils/column"], function (_exports, _component, _component2, _object, _rowSorting, _macros, _column) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Table header item used within [models-table/table-header](Components.ModelsTableTableHeader.html).
   *
   * Component generates tr with column titles in the separated cells. Click by each cell will sort table data by selected field. Check properties [disableSorting](Utils.ModelsTableColumn.html#property_disableSorting), [sortedBy](Utils.ModelsTableColumn.html#property_sortedBy) for ModelsTableColumn.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting />
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting as |RS|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <td>{{column.title}}</td>
   *         {{/each}}
   *       </Header.RowSorting>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * ModelsTableRowSorting yields references to the following contextual components:
   *
   * * [models-table/row-sorting-cell](Components.ModelsTableRowSortingCell.html) - component used as sorting row cell. Clicking on it causes column sorting
   *
   * References to the following properties are yielded:
   *
   * * [shouldAddExtraColumn](Components.ModelsTableRowSorting.html#property_shouldAddExtraColumn) - determines if extra column should be added to the row in the `thead`. It happens when rows grouping is used and extra column with group values exists
   *
   * Check own docs for each component to get detailed info.
   *
   * @namespace Components
   * @class ModelsTableRowSorting
   * @extends Ember.Component
   */
  let RowSortingComponent = _exports.default = (_dec = (0, _component.layout)(_rowSorting.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _macros.shownColumns)('colspanForSortCell'), _dec4 = (0, _object.computed)('displayGroupedValueAs', 'useDataGrouping', 'visibleProcessedColumns.[]'), _dec5 = (0, _object.computed)('currentGroupingPropertyName'), _dec(_class = _dec2(_class = (_class2 = class RowSortingComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'tr'
       */
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.processedColumns](Components.ModelsTable.html#property_processedColumns)
       *
       * @property processedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "processedColumns", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @default null
       * @type object[]
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @default null
       * @type object[]
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @default null
       * @type object[]
       */
      _defineProperty(this, "data", null);
      /**
       * Bound from [ModelsTable.useDataGrouping](Components.ModelsTable.html#property_useDataGrouping)
       *
       * @property useDataGrouping
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useDataGrouping", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @default null
       * @type string
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * Bound from [ModelsTable.groupHeaderCellComponent](Components.ModelsTable.html#property_groupHeaderCellComponent)
       *
       * @property groupHeaderCellComponent
       * @default null
       * @type object
       */
      _defineProperty(this, "groupHeaderCellComponent", null);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property currentGroupingPropertyName
       * @default null
       * @type object
       */
      _defineProperty(this, "currentGroupingPropertyName", null);
      /**
       * Closure action [ModelsTable.sort](Components.ModelsTable.html#event_sort)
       *
       * @event sort
       */
      _defineProperty(this, "sort", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @event toggleAllSelection
       */
      _defineProperty(this, "toggleAllSelection", null);
      /**
       * @property shownColumns
       * @type Utils.ModelsTableColumn[]
       * @protected
       */
      _initializerDefineProperty(this, "shownColumns", _descriptor, this);
    }
    /**
     * @property shouldAddExtraColumn
     * @type boolean
     * @default false
     * @protected
     */
    get shouldAddExtraColumn() {
      return this.displayGroupedValueAs === 'column' && this.useDataGrouping && !!this.visibleProcessedColumns.length;
    }

    /**
     * @property currentGroupingPropertyNameTitlelized
     * @type string
     * @protected
     */
    get currentGroupingPropertyNameTitlelized() {
      return (0, _column.propertyNameToTitle)(this.currentGroupingPropertyName);
    }

    /**
     * @event doSort
     * @param {Utils.ModelsTableColumn} column
     */
    doSort(column) {
      this.sort(column);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "shownColumns", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "shouldAddExtraColumn", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAddExtraColumn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentGroupingPropertyNameTitlelized", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "currentGroupingPropertyNameTitlelized"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doSort", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype)), _class2)) || _class) || _class);
});