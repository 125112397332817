define("ember-models-table/helpers/html-safe", ["exports", "@ember/string", "@ember/component/helper"], function (_exports, _string, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.htmlSafe = htmlSafe;
  function htmlSafe([str] /*, hash*/) {
    return (0, _string.htmlSafe)(str || '');
  }
  var _default = _exports.default = (0, _helper.helper)(htmlSafe);
});