define("ember-models-table/components/models-table/row-group-toggle", ["exports", "@ember-decorators/component", "@ember/component", "ember-models-table/templates/components/models-table/row-group-toggle", "@ember/object"], function (_exports, _component, _component2, _rowGroupToggle, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Component is used to toggle rows group visibility
   *
   * Don't override this component. Use [groupingRowComponent](Components.ModelsTable.html#property_groupingRowComponent) (it may extend this one)
   *
   * @namespace Components
   * @class ModelsTableRowGroupToggle
   * @extends Ember.Component
   */
  let RowGroupToggleComponent = _exports.default = (_dec = (0, _component.layout)(_rowGroupToggle.default), _dec(_class = (_class2 = class RowGroupToggleComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * Determines if `stopPropagation` should be called for event-handlers in the current component
       *
       * @property stopEventsPropagation
       * @type boolean
       * @default true
       */
      _defineProperty(this, "stopEventsPropagation", true);
      /**
       * Bound from [groupIsCollapsed](Components.ModelsTableRowGrouping.html#property_groupIsCollapsed)
       *
       * @property groupIsCollapsed
       * @type boolean
       * @default null
       */
      _defineProperty(this, "groupIsCollapsed", null);
      /**
       * Bound from [groupedValue](Components.ModelsTableRowGrouping.html#property_groupedValue)
       *
       * @property groupedValue
       * @type *
       * @default null
       */
      _defineProperty(this, "groupedValue", null);
      /**
       * List of all rows group items
       *
       * If rows group is last on the page, not all it's items may be shown. Use `visibleGroupedItems` to get rows group items shown on the current table's page
       *
       * @property groupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "groupedItems", null);
      /**
       * List of rows group items shown on the current table page
       *
       * @property visibleGroupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "visibleGroupedItems", null);
      /**
       * List of selected rows group items
       *
       * @property selectedGroupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedGroupedItems", null);
      /**
       * List of expanded rows group items
       *
       * @property expandedGroupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "expandedGroupedItems", null);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property currentGroupingPropertyName
       * @type string
       * @default null
       */
      _defineProperty(this, "currentGroupingPropertyName", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @type string
       * @default null
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRows](Components.ModelsTable.html#event_toggleGroupedRows)
       *
       * @event toggleGroupedRows
       */
      _defineProperty(this, "toggleGroupedRows", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsSelection](Components.ModelsTable.html#event_toggleGroupedRowsSelection)
       *
       * @event toggleGroupedRowsSelection
       */
      _defineProperty(this, "toggleGroupedRowsSelection", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsExpands](Components.ModelsTable.html#event_toggleGroupedRowsExpands)
       *
       * @event toggleGroupedRowsExpands
       */
      _defineProperty(this, "toggleGroupedRowsExpands", null);
    }
    /**
     * Calls passed `toggleGroupedRows`
     *
     * @event toggleGroupedRows
     */
    doToggleGroupedRows() {
      this.toggleGroupedRows(this.groupedValue);
      return !this.stopEventsPropagation;
    }

    /**
     * Calls passed `toggleGroupedRowsSelection`
     *
     * @event toggleGroupedRowsSelection
     */
    doToggleGroupedRowsSelection() {
      this.toggleGroupedRowsSelection(this.groupedValue);
      return !this.stopEventsPropagation;
    }

    /**
     * Calls passed `toggleGroupedRowsExpands`
     *
     * @event toggleGroupedRowsExpands
     */
    doToggleGroupedRowsExpands() {
      this.toggleGroupedRowsExpands(this.groupedValue);
      return !this.stopEventsPropagation;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "doToggleGroupedRows", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRows"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doToggleGroupedRowsSelection", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRowsSelection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doToggleGroupedRowsExpands", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRowsExpands"), _class2.prototype)), _class2)) || _class);
});