define('ember-time-field/states/focused', ['exports', 'ember-time-field/utils/state', 'ember-time-field/states/hours-focused', 'ember-time-field/states/minutes-focused', 'ember-time-field/states/period-focused'], function (exports, _state, _hoursFocused, _minutesFocused, _periodFocused) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _state.default.extend({
    hours: (0, _state.state)(_hoursFocused.default),
    minutes: (0, _state.state)(_minutesFocused.default),
    period: (0, _state.state)(_periodFocused.default),

    focusOut(manager) {
      manager.transitionTo("unfocused");
    },

    refocus(manager) {
      manager.send("focusIn");
    },

    left() {
      // no-op if not handled by child
    },

    right() {
      // no-op if not handled by child
    }
  });
});