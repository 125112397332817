define("ember-models-table/components/models-table/no-data", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-models-table/templates/components/models-table/no-data"], function (_exports, _component, _component2, _object, _noData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Table row shown when no data provided to the ModelsTable. Component used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * This component is a table-row with a single cell. This cell has a `colspan` equal to the shown columns count.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.visibleContent.length}}
   *         {{! ... }}
   *       {{else}}
   *         <Body.NoData />
   *       {{/if}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.visibleContent.length}}
   *         {{! ... }}
   *       {{else}}
   *         <Body.NoData as |ND|>
   *           Any message here
   *         </Body.NoData>
   *       {{/if}}
   *     </table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableNoData
   * @namespace Components
   * @extends Ember.Component
   */
  let NoDataComponent = _exports.default = (_dec = (0, _component.layout)(_noData.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _object.computed)('columnsCount', 'displayGroupedValueAs'), _dec(_class = _dec2(_class = (_class2 = class NoDataComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * Equal to [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns).length
       *
       * @property columnsCount
       * @type number
       * @default null
       */
      _defineProperty(this, "columnsCount", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
    /**
     * @property tagName
     * @type string
     * @default 'tr'
     */

    /**
     * @property realColumnsCount
     * @type number
     * @protected
     */
    get realColumnsCount() {
      return this.columnsCount + (this.displayGroupedValueAs === 'column' ? 1 : 0);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "realColumnsCount", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "realColumnsCount"), _class2.prototype)), _class2)) || _class) || _class);
});