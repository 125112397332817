define("ember-models-table/components/models-table/summary", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-models-table/templates/components/models-table/summary", "ember-models-table/utils/fmt"], function (_exports, _component, _component2, _object, _computed, _summary, _fmt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Summary block used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.Summary />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * References to the following properties are yielded:
   *
   * * [summary](Components.ModelsTableSummary.html#property_summary) - message like "Show xx - yy from zzz"
   *
   * @class ModelsTableSummary
   * @namespace Components
   * @extends Ember.Component
   */
  let SummaryComponent = _exports.default = (_dec = (0, _component.layout)(_summary.default), _dec2 = (0, _computed.alias)('themeInstance.footerSummary'), _dec3 = (0, _object.computed)('useNumericPagination', 'themeInstance.{footerSummaryNumericPagination,footerSummaryDefaultPagination}'), _dec4 = (0, _object.computed)('firstIndex', 'lastIndex', 'msg', 'recordsCount', 'themeInstance.tableSummaryMsg'), _dec5 = (0, _object.computed)('elementId'), _dec(_class = (_class2 = class SummaryComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property themeFooterSummaryClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeFooterSummaryClass", _descriptor, this);
      /**
       * Bound from [ModelsTable.firstIndex](Components.ModelsTable.html#property_firstIndex)
       *
       * @property firstIndex
       * @type number
       * @default null
       */
      _defineProperty(this, "firstIndex", null);
      /**
       * Bound from [ModelsTable.lastIndex](Components.ModelsTable.html#property_lastIndex)
       *
       * @property lastIndex
       * @type number
       * @default null
       */
      _defineProperty(this, "lastIndex", null);
      /**
       * Bound from [ModelsTable.arrangedContentLength](Components.ModelsTable.html#property_arrangedContentLength)
       *
       * @property recordsCount
       * @type number
       * @default null
       */
      _defineProperty(this, "recordsCount", null);
      /**
       * Bound from [ModelsTable.anyFilterUsed](Components.ModelsTable.html#property_anyFilterUsed)
       *
       * @property anyFilterUsed
       * @type boolean
       * @default null
       */
      _defineProperty(this, "anyFilterUsed", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Closure action [ModelsTable.clearFilters](Components.ModelsTable.html#event_clearFilters)
       *
       * @event clearFilters
       */
      _defineProperty(this, "clearFilters", null);
      /**
       * Bound from [ModelsTable.useNumericPagination](Components.ModelsTable.html#property_useNumericPagination)
       *
       * @property useNumericPagination
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useNumericPagination", null);
    }
    /**
     * @property paginationTypeClass
     * @type string
     * @protected
     */
    get paginationTypeClass() {
      return this.useNumericPagination ? this.themeInstance.footerSummaryNumericPagination : this.themeInstance.footerSummaryDefaultPagination;
    }
    /**
     * @property summary
     * @type string
     * @protected
     */
    get summary() {
      return (0, _fmt.default)(this.themeInstance.tableSummaryMsg, this.firstIndex, this.lastIndex, this.recordsCount);
    }

    /**
     * @property inputId
     * @type string
     * @protected
     */
    get inputId() {
      return `${this.elementId}-summary-input`;
    }

    /**
     * @event doClearFilters
     * @param {Event} e
     * @protected
     */
    doClearFilters(e) {
      if (e) {
        e.stopPropagation();
      }
      this.clearFilters();
    }

    /**
     * @event noop
     * @protected
     */
    noop() {}
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "themeFooterSummaryClass", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "paginationTypeClass", [_component.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "paginationTypeClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "summary", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "summary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doClearFilters", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doClearFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype)), _class2)) || _class);
});