define("ember-time-field/states/unfocused", ["exports", "ember-time-field/utils/state"], function (exports, _state) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _state.default.extend({
    focusIn(manager) {
      manager.transitionTo("focused.hours");
    },

    // it's possible to receive events after losing focus, due to runloop shenanigans
    // just swallow them as no-ops
    refocus() {},
    focusOut() {},
    up() {},
    down() {},
    left() {},
    right() {},
    key() {}
  });
});